import React, { SyntheticEvent, } from "react"
import classnames from "classnames"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import IconButton from "@material-ui/core/IconButton"
import style from "./style.module.scss"

export enum IconSize {
  S = "small",
  M = "default",
  L = "large"
}

type Props = {
  iconSize: IconSize
  gooded: boolean
  onClick: (e: SyntheticEvent) => void
}

export default React.memo(function GoodIconButton ({
  iconSize, gooded, onClick,
}: Props) {
  return (
    <IconButton onClick={onClick}>
      <FavoriteBorderIcon
        fontSize={iconSize}
        className={classnames({
          [style.good]: true,
          [style.gooded]: gooded,
        })}
      />
    </IconButton>
  )
})
