import React, { useState, useEffect, createRef, } from "react"
import classnames from "classnames"
import ShareIcon from "@material-ui/icons/Share"
import IconButton from "@material-ui/core/IconButton"
import { createMarker, createMobileSceneUrl, } from "utils/marker"
import style from "./style.module.scss"
import { preventScroll, enableScroll, } from "utils/scroll"

/**
 * sceneId ID of a scene.
 * thumbnailUrl Thumbnail image URL.
 * onMobileUrlCopy Callback of clicking mobile URL copy button.
 * open Whether to open display or not.
 * onClose Callback of closing request from this component.
 */
type Props = {
  sceneId: string,
  thumbnailUrl: string,
  onShare: () => void,
  open: boolean,
  onClose: () => void
}

/**
 * Marker Display (dialog)
 */
export default function MarkerPopup ({
  sceneId,
  thumbnailUrl,
  onShare,
  open,
  onClose,
}: Props) {
  // Mobile scene URL
  const mobileUrl = createMobileSceneUrl(sceneId)
  // QR data URL of marker and the setter.
  const [markerDataUrl, setMarkerDataUrl] = useState(null as (string | null))
  // Whether marker image has been loaded and setter.
  const [markerLoaded, setMarkerLoaded] = useState(false)
  // Whether to show the marker display itself or not and setter.
  const [show, setShow] = useState(false)
  // Whether to show the frame (main content) or not and setter.
  const [showContent, setShowContent] = useState(false)
  /**
   * Fires on the share button is clicked.
   */
  const onShareClickInternal = () => {
    onShare && onShare()
  }
  /**
   * Fires on marker is loaded.
   * This does not mean when a marker URL is created.
   */
  const onMarkerLoad = () => setMarkerLoaded(true)
  /**
   * Handles cover clicking event.
   * Closes if the click target is the cover.
   * @param {Event} e
   */
  const onCoverClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === coverRef.current) {
      close()
    }
  }
  /**
   * Call onClose callback to notify closing from inside to outside.
   */
  const close = () => {
    onClose && onClose()
  }
  // Generates marker data URL
  useEffect(() => {
    if (open) {
      ;(async () => {
        try {
          const markerDataUrl = await createMarker(thumbnailUrl, mobileUrl)
          setMarkerDataUrl(markerDataUrl)
        } catch (e) {
          console.error("Error")
        }
      })()
    }
  }, [open, mobileUrl, thumbnailUrl])
  // Handle CSS class handling
  useEffect(() => {
    if (open) {
      preventScroll()
      setTimeout(() => {
        setShow(true)
        setTimeout(() => {
          setShowContent(true)
        }, 100)
      }, 50)
    } else {
      enableScroll()
      setShowContent(false)
      setTimeout(() => {
        setShow(false)
      }, 400)
    }
    return () => { enableScroll() }
  }, [open])
  // Reference of the cover element.
  const coverRef = createRef<HTMLDivElement>()

  return (
    <div
      className={classnames({
        [style.wrapper]: true,
        [style.show]: show,
      })}
    >
      <div
        className={classnames({
          [style.cover]: true,
          [style.showContent]: showContent,
        })}
        onClick={onCoverClick}
        ref={coverRef}
      >
        <div
          className={classnames({
            [style.frame]: true,
            [style.show]: showContent,
          })}
        >
          <div className={style.header}>
            <div className={style.title}>STYLY Mobile App Marker</div>
            <div className={style.buttonContainer}>
              <IconButton onClick={onShareClickInternal} size="small">
                <ShareIcon />
              </IconButton>
            </div>
          </div>
          <div className={style.markerContainer}>
            <img
              alt="marker"
              className={classnames({
                [style.loaded]: markerLoaded,
              })}
              src={markerDataUrl || ""}
              onLoad={onMarkerLoad}
            />
          </div>
          <div className={style.footer}>
            <div className={style.description}>
              Please scan this marker to launch the AR scene with STYLY Mobile
              app. Check &nbsp;
              <a target="_blank" href="https://styly.cc/mobile/" rel="noopener noreferrer">
                our article about STYLY Mobile
              </a>
              . * Scenes not tagged with #AR may not work on STYLY Mobile app
              properly.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
