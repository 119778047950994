import style from "./style.module.scss"
import SceneGrid from "features/SceneGrid"
import React, { useEffect, useRef, } from "react"
import { selectState as appState, doGood, undoGood, addToMylist, removeFromMylist, } from "app/App/redux"
import { fetchScenes, fetchMoreScenes, } from "./redux"
import { useSelector, ConnectedProps, connect, } from "react-redux"
import { RouteComponentProps, } from "react-router-dom"
import { RootState, } from "app/store"
import { Location, } from "history"
import queryString from "query-string"
import SceneLoadingPlaceholder from "features/SceneLoadingPlaceholder"
import { changeText, } from "reduxComponents/SearchBox/redux"
import history from "utils/history"
import classnames from "classnames"

const mapState = (state: RootState) => ({ ...state.sceneSearch, })
const mapDispatch = { fetchScenes, fetchMoreScenes, changeText, doGood, undoGood, addToMylist, removeFromMylist, }
const connector = connect(mapState, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps

function parseQuery (location: Location) {
  let query = queryString.parse(location.search).q || ""
  if (Array.isArray(query)) {
    query = query[0]
  }
  return query
}

function SceneSearchPage ({
  location,
  pickupScenes,
  scenes,
  total,
  loadingScenes,
  fetchScenes,
  fetchMoreScenes,
  initialLoadDone,
  changeText,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
}: Props) {
  const { me, } = useSelector(appState)
  const gridRef = useRef<HTMLDivElement>(null)

  const query = parseQuery(location)

  // initial scene loading
  useEffect(() => {
    fetchScenes(query)
    changeText(query)
  }, [changeText, fetchScenes, query])

  // incremental scene loading
  useEffect(() => {
    const trackScrolling = () => {
      if (loadingScenes || scenes.length === total) {
        return
      }
      if (!gridRef?.current) {
        return
      }
      if (gridRef.current.getBoundingClientRect().bottom < window.innerHeight + 10) {
        fetchMoreScenes(query)
      }
    }

    document.addEventListener("scroll", trackScrolling)
    return () => document.removeEventListener("scroll", trackScrolling)
  }, [fetchMoreScenes, loadingScenes, query, scenes.length, total])

  /**
   * いいねクリック時の処理
   */
  const onGoodClick = (sceneId: string, gooded: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (gooded) {
      undoGood(sceneId)
    } else {
      doGood(sceneId)
    }
  }

  /**
   * マイリストアイコンクリック時の処理
   */
  const onMylistClick = (sceneId: string, mylisted: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (mylisted) {
      removeFromMylist(sceneId)
    } else {
      addToMylist(sceneId)
    }
  }

  return (
    <div className={style.frame}>
      <div className={style.header}>
        Search&nbsp;
        {query || "All"}
      </div>
      {pickupScenes.length > 0 && (
        <>
          <div className={style.subHeader}>Pickup</div>
          <SceneGrid scenes={pickupScenes} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
        </>
      )}
      <div ref={gridRef}>
        {pickupScenes.length > 0 && (
          <div className={classnames({
            [style.subHeader]: true,
            [style.withTopMargin]: pickupScenes.length > 0,
          })}>Latest</div>
        )}
        <SceneGrid scenes={scenes} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
      </div>
      {loadingScenes && <SceneLoadingPlaceholder />}
      {initialLoadDone && scenes.length === 0 && (
        <div className={style.noScenesMessage}>
          <div className={style.message}>No scene is found.</div>
        </div>
      )}
    </div>
  )
}

export default connector(SceneSearchPage)
