import React from "react"
import classnames from "classnames"
import Button from "@material-ui/core/Button"
import style from "./style.module.scss"

type Props = {
  isFollowed: boolean
  onClick: () => void
}

export default function FollowButton ({
  isFollowed,
  onClick,
}: Props) {
  return (
    <Button
      variant='contained'
      size='large'
      onClick={onClick}
      className={classnames({
        [style.followButton]: true,
        [style.followed]: isFollowed,
      })}
    >
      <span>{(isFollowed) ? "UNFOLLOW" : "FOLLOW"}</span>
    </Button>
  )
}
