import React from "react"
import AccountIcon, { Size as AccountIconSize, } from "features/AccountIcon"
import User from "utils/api/models/User"
import style from "./style.module.scss"
import { ScreenUpperWidth, } from "app/constants"
import FollowButton from "features/FollowButton"

interface Props {
  user: User
  onUserClick: () => void
  isFollowed: boolean
  onFollowClick: (userId: number) => void
  onUnFollowClick: (userId: number) => void
}

export default function FollowingUser ({
  user,
  onUserClick,
  isFollowed,
  onFollowClick,
  onUnFollowClick,
}: Props) {
  /**
   * フォローボタン押下時の処理
   */
  const onFollowButtonClick = () => {
    if (isFollowed) {
      onUnFollowClick(user.id)
    } else {
      onFollowClick(user.id)
    }
  }

  return (
    <div className={style.cellContainer}>
      <div className={style.iconContainer}>
        <AccountIcon
          username={user.username}
          profileImageUrl={user.profileImageUrl || ""}
          size={document.body.clientWidth > ScreenUpperWidth.PHONE
            ? AccountIconSize.L : AccountIconSize.M
          }
          onClick={onUserClick}
        />
      </div>
      <div className={style.textRowContainer}>
        <div className={style.usernameLine} onClick={onUserClick}>
          {user.username}
        </div>
      </div>
      <div className={style.followButtonContainer}>
        <FollowButton
          isFollowed={isFollowed}
          onClick={onFollowButtonClick}
        />
      </div>
    </div>
  )
}
