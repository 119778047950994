import React from "react"
import classnames from "classnames"
import style from "./style.module.scss"

/**
 * Icon のサイズ
 * 実際のサイズは上記の CSS を参照のこと
 * @enum {string}
 */
export enum Size {
  S = "small",
  M = "medium",
  L = "large"
}

type Props = {
  username: string,
  profileImageUrl: string | null,
  size?: Size,
  onClick?: () => void
}

/**
 * 円形のアカウントアイコン
 * 写真がないときはユーザーネームの頭文字が表示される
 */
export default function AccountIcon ({
  username,
  profileImageUrl,
  size = Size.S,
  onClick,
}: Props) {
  /**
   * クリックされたときのコールバック
   * @param {Event} e
   */
  const onClickInternal = () => {
    if (onClick) onClick()
  }

  // 頭文字を取得. username がないときは ? を表示
  const initial =
    username && username.length > 0 ? username[0].toUpperCase() : "?"
  return (
    <div
      onClick={onClickInternal}
      className={classnames({
        [style.frame]: true,
        [style.hasImage]: !!profileImageUrl,
      })}
    >
      <div className={classnames(style.iconCircle, style[size])}>
        {initial}
      </div>
      {profileImageUrl && (
        <img
          alt="profile"
          className={style.imgCircle}
          src={profileImageUrl}
          onError={e => {
            e.currentTarget.style.display = "none"
          }}
        />
      )}
    </div>
  )
}
