export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH || ""
export const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || ""
export const CONTENTFUL_ENV = process.env.REACT_APP_CONTENTFUL_ENV || ""
export const CONTENTFUL_SPACE = process.env.REACT_APP_CONTENTFUL_SPACE || ""
export const ENABLE_REDUX_LOGGER = process.env.REACT_APP_ENABLE_REDUX_LOGGER === "true"
export const GOOGLE_ANALYTICS_CODE = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE || ""

/**
 * 一部で使用しているv1のAPIでサムネイル画像のURLとして使用するAzureのパス
 */
export const AZURE_BASE_PATH = process.env.REACT_APP_AZURE_BASE_PATH || "https://styly-azure-upload-bucket.imgix.net"
