import React from "react"

type Props = {
  children: any,
  index: string
}

export default function TabPanel ({ children, index, }: Props) {
  return (
    <div
      role="tabpanel"
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {children}
    </div>
  )
}
