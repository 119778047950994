import ReactGA, { EventArgs, } from "react-ga"

enum GaCategory {
  USER = "USER",
  SCENE_ACTION = "ACTION",
}

export default class Tracker {
  /**
   * Google Analytics Tracking Code.
   */
  googleAnalyticsCode: string

  /**
   * Whether to enable tracking or not.
   */
  notTrack: boolean = false

  /**
   * @param googleAnalyticsCode
   */
  constructor (googleAnalyticsCode: string) {
    this.googleAnalyticsCode = googleAnalyticsCode
  }

  initialize (notTrack: boolean) {
    this.notTrack = notTrack
    if (!notTrack) {
      ReactGA.initialize(this.googleAnalyticsCode)
    }
  }

  identify (userId: number): void {
    if (userId < 0) {
      throw new Error("User ID must be more than 0.")
    }
    if (!this.notTrack) {
      ReactGA.set({ userId, })
    }
  }

  pageview (): void {
    if (!this.notTrack) {
      ReactGA.pageview(window.location.pathname)
    }
  }

  sendGaEvent (eventArgs: EventArgs) {
    if (!this.notTrack) {
      ReactGA.event(eventArgs)
    }
  }

  signupOpen (): void {
    this.sendGaEvent({
      category: GaCategory.USER,
      action: "Signup Open",
    })
  }

  signup (): void {
    this.sendGaEvent({
      category: GaCategory.USER,
      action: "Signup",
    })
  }

  login (): void {
    this.sendGaEvent({
      category: GaCategory.USER,
      action: "Login",
    })
  }

  playScene (sceneId: string): void {
    this.sendGaEvent({
      category: GaCategory.SCENE_ACTION,
      action: "Play",
      label: sceneId,
    })
  }

  good (sceneId: string): void {
    this.sendGaEvent({
      category: GaCategory.SCENE_ACTION,
      action: "Good",
      label: sceneId,
    })
  }

  mylist (sceneId: string): void {
    this.sendGaEvent({
      category: GaCategory.SCENE_ACTION,
      action: "Mylist",
      label: sceneId,
    })
  }
}
