import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit"
import ApiClient from "utils/api"
import User from "utils/api/models/User"
import SceneListItem from "utils/api/models/SceneListItem"
import { RootState, } from "app/store"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import { API_BASE_PATH, } from "app/env"
import { doGood, undoGood, addToMylist, removeFromMylist, follow, unfollow, logout, } from "app/App/redux"
import axios, { AxiosResponse, } from "axios"

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/UserPage`

const client = new ApiClient(API_BASE_PATH)

const NUM_SCENES_OF_CHUNK = 48

export const initialize = createAsyncThunk<{
  user: User,
  scenes: SceneListItem[],
  total: number
}, number, {}>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (id: number) => {
    const user = await client.getUserById(id)
    user.followerCount = await getFollowerCount(user.username)
    const { scenes, total, } = await client.getScenesByUserId(id, NUM_SCENES_OF_CHUNK, 0)
    return {
      user,
      scenes,
      total,
    }
  }
)

export const fetchMoreScenes = createAsyncThunk(
  `${ACTION_TYPE_PREFIX}/fetchMoreScenes`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const { scenes, user, } = state.userPage
    const offset = scenes.length
    if (!user) {
      throw new Error("No user")
    }
    const res = await client.getScenesByUserId(user.id, NUM_SCENES_OF_CHUNK, offset)
    return res
  }
)

/**
 * ユーザーのフォロワー数を取得するアクション
 */
export const fetchFollowerCount = createAsyncThunk<number, string>(
  `${ACTION_TYPE_PREFIX}/fetchFollowerCount`,
  async (username: string) => {
    const followerCount = await getFollowerCount(username)
    return followerCount
  }
)

/**
 * フォロー状況を取得するアクション
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
export const fetchFollowing = createAsyncThunk<boolean, number>(
  `${ACTION_TYPE_PREFIX}/fetchFollowing`,
  async (id: number) => {
    // eslint-disable-next-line camelcase
    interface ResponseData {is_followed: boolean}
    const isFollowed = (await axios.create({
      withCredentials: true,
    }).get(`${API_BASE_PATH}/brand_user/follow?creator_follow_serial=${id}`) as AxiosResponse<ResponseData>).data.is_followed
    return isFollowed
  }
)

/**
 * ユーザーのフォロワー数を取得する
 * V2のAPIができるまでの間、暫定でV1のAPIを使用しています
 */
async function getFollowerCount (username: string) {
  interface ResponseData {
    creator: {
      // eslint-disable-next-line camelcase
      follower_count: number
    }
  }
  const result = await axios.create({
    withCredentials: true,
  }).get(`${API_BASE_PATH}/creator/${username}/scene`) as AxiosResponse<ResponseData>
  return result.data.creator.follower_count
}

const initialState = {
  user: null as (User | null),
  isFollowed: false,
  initialized: false,
  initializing: false,
  fetchingScenes: false,
  scenes: [] as SceneListItem[],
  total: 0,
}

export const slice = createSlice({
  name: `${ACTION_TYPE_PREFIX}/scenePage`,
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        return {
          ...state,
          initialized: false,
          initializing: true,
          fetchingScenes: true,
          scenes: [],
          total: 0,
        }
      })
      .addCase(initialize.rejected, (state) => {
        return {
          ...state,
          initializing: false,
          fetchingScenes: false,
        }
      })
      .addCase(initialize.fulfilled, (state, action) => {
        const {
          scenes,
          user,
          total,
        } = action.payload
        return {
          ...state,
          initialized: true,
          initializing: false,
          fetchingScenes: false,
          scenes,
          user,
          total,
        }
      })
      .addCase(fetchMoreScenes.pending, (state) => {
        return {
          ...state,
          fetchingScenes: true,
        }
      })
      .addCase(fetchMoreScenes.rejected, (state) => {
        return {
          ...state,
          fetchScenes: false,
        }
      })
      .addCase(fetchMoreScenes.fulfilled, (state, action) => {
        const {
          scenes: newScenes,
        } = action.payload
        const scenes = [...state.scenes, ...newScenes]
        return {
          ...state,
          fetchingScenes: false,
          scenes,
        }
      })
      .addCase(fetchFollowerCount.fulfilled, (state, action) => {
        // フォロワー数を更新する
        if (state.user) {
          state.user.followerCount = action.payload
        }
        return state
      })
      .addCase(fetchFollowing.fulfilled, (state, action) => {
        // フォロー状況を更新する
        const isFollowed = action.payload
        return {
          ...state,
          isFollowed: isFollowed,
        }
      })
      .addCase(doGood.fulfilled, (state, action) => {
        // いいね状況を更新する
        const sceneId = action.payload
        changeScenesGooded(true, state.scenes, sceneId)
        return state
      })
      .addCase(undoGood.fulfilled, (state, action) => {
        // いいね状況を更新する
        const sceneId = action.payload
        changeScenesGooded(false, state.scenes, sceneId)
        return state
      })
      .addCase(addToMylist.fulfilled, (state, action) => {
        // マイリスト状況を更新する
        const sceneId = action.payload.id
        changeScenesMylisted(true, state.scenes, sceneId)
        return state
      })
      .addCase(removeFromMylist.fulfilled, (state, action) => {
        // マイリスト状況を更新する
        const sceneId = action.payload
        changeScenesMylisted(false, state.scenes, sceneId)
        return state
      })
      .addCase(follow.fulfilled, (state, action) => {
        const userId = action.payload
        // フォロー状況を更新する
        if (state.user && state.user.id === userId) {
          state.isFollowed = true
        }
        return state
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        const userId = action.payload
        // フォロー状況を更新する
        if (state.user && state.user.id === userId) {
          state.isFollowed = false
        }
        return state
      })
      .addCase(logout.fulfilled, () => {
        // ログアウト後はstateを初期化する
        return initialState
      })
  },
})

/**
 * このユーザーが公開したシーンのリストに対して
 * sceneIdが指すシーンのマイリスト状況を変更する
 */
const changeScenesMylisted = (mylisted: boolean, scenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < scenes.length; i++) {
    const scene = scenes[i]
    if (scene.id !== sceneId) continue

    scene.mylisted = mylisted
    break
  }
}

/**
 * このユーザーが公開したシーンのリストに対して
 * sceneIdが指すシーンのいいね状況を変更する
 */
const changeScenesGooded = (gooded: boolean, scenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < scenes.length; i++) {
    const scene = scenes[i]
    if (scene.id !== sceneId) continue

    scene.gooded = gooded
    break
  }
}

export const selectState = (state: RootState) => state.userPage

export default slice.reducer
