import React, { useEffect, useState, useRef, } from "react"
import {
  logout,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
  selectState as appState,
} from "app/App/redux"
import { initialize, slice, TabType, fetchFollowList, fetchMylistedScenes, selectState, } from "./redux"
import { useSelector, connect, ConnectedProps, } from "react-redux"
import SceneGrid from "features/SceneGrid"
import TabPanel from "./TabPanel"
import history from "utils/history"
import style from "./style.module.scss"
import InfoRow from "./InfoRow"
import TabsContainer from "./TabsContainer"
import MenuIcon from "./MenuIcon"
import FollowingUser from "./FollowingUser"
import { tunnelClient, } from "globalInstance"
import CircularProgress from "@material-ui/core/CircularProgress"
import SwipeableViews from "react-swipeable-views"
import i18next from "i18next"

const tabLabel = {
  // [TabType.HOME]: "HOME",
  [TabType.MY_SCENE]: "SCENE",
  [TabType.MY_LIST]: "MY LIST",
  [TabType.FOLLOWING]: "FOLLOWING",
}

const tabOrder = [
  // TabType.HOME,
  TabType.MY_SCENE,
  TabType.MY_LIST,
  TabType.FOLLOWING
]

const mapDispatch = {
  logout,
  initialize,
  setTab: slice.actions.setTab,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
  fetchFollowList,
  fetchMylistedScenes,
}
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector>

function MyPage ({
  logout,
  initialize,
  setTab,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  unfollow,
  fetchFollowList,
  fetchMylistedScenes,
}: Props) {
  const { me, } = useSelector(appState)
  const {
    fetchingMyScenes,
    // fetchingScenesOfFollows,
    fetchingFollowList,
    fetchingMylistedScenes,
    // scenesOfFollows,
    mylistedScenes,
    followList,
    myScenes,
    tab,
    followerCount,
    totalFollowees,
  } = useSelector(selectState)
  const followeeGridRef = useRef<HTMLDivElement>(null)
  const [initialized, setInitialized] = useState(false)

  // マイページの初期化
  useEffect(() => {
    if (me && !initialized) {
      const mypageInit = async () => {
        await initialize(me.username)
        setInitialized(true)
      }
      mypageInit()
    }
  }, [initialized, initialize, me])

  useEffect(() => {
    const trackScrolling = () => {
      if (fetchingFollowList || followList.length === totalFollowees) {
        return
      }
      if (!followeeGridRef?.current) {
        return
      }
      if (followeeGridRef.current.getBoundingClientRect().bottom < window.innerHeight + 10) {
        fetchFollowList()
      }
    }
    document.addEventListener("scroll", trackScrolling)
    return () => document.removeEventListener("scroll", trackScrolling)
  }, [fetchFollowList, fetchingFollowList, totalFollowees, followList.length])

  /**
   * ログアウト押下時の処理
   */
  const onLogoutClick = async () => {
    await logout()
    history.push("/")
  }

  /**
   * タブ変更時の処理
   * @param newValue 選択したタブのTabType
   */
  const handleChangeTab = (newValue: TabType) => {
    setTab(newValue)
  }

  /**
   * いいねクリック時の処理
   */
  const onGoodClick = (sceneId: string, gooded: boolean) => {
    if (gooded) {
      undoGood(sceneId)
    } else {
      doGood(sceneId)
    }
  }

  /**
   * マイリストアイコンクリック時の処理
   */
  const onMylistClick = async (sceneId: string, mylisted: boolean) => {
    if (mylisted) {
      await removeFromMylist(sceneId)
    } else {
      await addToMylist(sceneId)
    }

    // マイリストを更新
    fetchMylistedScenes()
  }

  /**
   * UNFOLLOWクリック時の処理
   */
  const onUnfollowClick = (userId: number) => {
    // フォローを外す
    unfollow(userId)
  }

  /**
   * SNSアイコンクリック時の処理
   */
  const onSnsIconClick = async (url: string) => {
    // urlを外部アプリで開く
    await tunnelClient.openExternalPage(url)
  }

  const onSwipeIndexChange = (index: number) => {
    const currentTab = tabOrder[index]
    handleChangeTab(currentTab)
  }

  // ログインしていない場合はSignupページへ遷移する
  if (!me) {
    history.push("/login?signup")
  }

  const tabIndex = tabOrder.findIndex((value) => {
    return tab === value
  })

  return (
    <div className={style.frame}>
      <div className={style.menuIcon}>
        <MenuIcon onLogoutClick={onLogoutClick} />
      </div>
      <div className={style.infoRow}>
        {me && (
          <InfoRow me={me}
            totalScenes={myScenes.length}
            totalFollowers={followerCount}
            onSnsIconClick={onSnsIconClick}
          />
        )}
      </div>
      <div>
        <TabsContainer
          tab={tab}
          tabOrder={tabOrder}
          tabLabel={tabLabel}
          onChangeTab={handleChangeTab}
        />
        <div className={style.contentContainer}>
          <SwipeableViews
            index={tabIndex}
            onChangeIndex={onSwipeIndexChange}
            enableMouseEvents
          >
            <TabPanel index={TabType.MY_SCENE}>
              {fetchingMyScenes && (
                <div className={style.spinnerContainer}>
                  <CircularProgress/>
                </div>
              )}
              {!fetchingMyScenes && (myScenes.length === 0 ? (
                <div className={style.emptyMessage}>
                  <div className={style.header}>{i18next.t("myPage.noPublishedScene")}</div>
                  <div className={style.sentence}>{i18next.t("myPage.goStudioAndPublish")}</div>
                </div>
              ) : (
                <SceneGrid scenes={myScenes} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
              ))}
            </TabPanel>
            <TabPanel index={TabType.MY_LIST}>
              {fetchingMylistedScenes && (
                <div className={style.spinnerContainer}>
                  <CircularProgress/>
                </div>
              )}
              {!fetchingMylistedScenes && (mylistedScenes.length === 0 ? (
                <div className={style.emptyMessage}>
                  <div className={style.header}>{i18next.t("myPage.noMylistedScene")}</div>
                  <div className={style.sentence}>{i18next.t("myPage.mylistShowUp")}</div>
                </div>
              ) : (
                <SceneGrid scenes={mylistedScenes} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
              ))}
            </TabPanel>
            <TabPanel index={TabType.FOLLOWING}>
              {fetchingFollowList && (
                <div className={style.spinnerContainer}>
                  <CircularProgress/>
                </div>
              )}
              {!fetchingFollowList && (followList.length === 0 ? (
                <div className={style.emptyMessage}>
                  <div className={style.header}>{i18next.t("myPage.noFollowee")}</div>
                  <div className={style.sentence}>{i18next.t("myPage.followeeShowUp")}</div>
                </div>
              ) : (
                <div ref={followeeGridRef}>
                  {followList.map((f) => {
                    return (
                      <FollowingUser
                        key={f.id}
                        user={f}
                        onUserClick={() => {
                          history.push(`/user/${f.id}`)
                        }}
                        isFollowed={true}
                        onFollowClick={() => {}}
                        onUnFollowClick={() => onUnfollowClick(f.id)}
                      />
                    )
                  })}
                </div>
              ))}
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>
  )
}

export default connector(MyPage)
