import { createMuiTheme, } from "@material-ui/core/styles"

export default createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#FFF",
      // LIGHT_GRAY
      contrastText: "#eee",
    },
  },
})
