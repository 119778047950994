import { Tunnel, Request, } from "tunnel"

export enum Permission {
  CAMERA = "CAMERA",
  NOTIFICATION = "NOTIFICATION",
}

/**
 * Wrapper of tunnel with functions for actual usecases.
 */
export class TunnelClient {
  private tunnel: Tunnel

  private permissionBeingAsked: Permission | null = null
  private askPermissionResolve: ((granted: boolean) => void) | null = null
  private askPermissionReject: ((e: Error) => void) | null = null
  private navigatorBackCallback: (() => boolean) | null = null

  constructor () {
    this.tunnel = new Tunnel()
    this.tunnel.setOnRequest(this.onRequest.bind(this))
    this.tunnel.open()
  }

  /**
   * Callback to receive response.
   */
  private onRequest = (req: Request) => {
    if (!req.payload) {
      console.warn("Request without payload received.")
    }
    if (req.context === "ECHO") {
      if (
        !req.payload.message ||
        (typeof req.payload.message) !== "string"
      ) {
        req.respondError("No message. Please send message.")
      } else {
        req.respondOk({ message: req.payload.message, })
      }
    }
    if (req.context === "PERMISSION_GRANTED") {
      if (this.permissionBeingAsked === req.payload.type) {
        if (typeof req.payload.granted === "boolean") {
          this.askPermissionResolve?.(req.payload.granted)
        } else {
          this.askPermissionReject?.(new Error("granted is not boolean."))
        }
        this.permissionBeingAsked = null
        this.askPermissionResolve = null
        this.askPermissionReject = null
        req.respondOk({})
      }
    }
    // バックボタンの通知
    if (req.context === "NAVIGATOR_BACK") {
      if (this.navigatorBackCallback) {
        const didBack = this.navigatorBackCallback()
        req.respondOk({ didBack, })
      }
    }
  }

  /**
   * Gets whether tunnel is ready to communicate with.
   */
  ready (): boolean {
    return this.tunnel.ready()
  }

  /**
   * General function to send a request to Unity.
   */
  private sendRequest<TReqPayload, TResPayload> (
    context: string,
    reqPayload: TReqPayload
  ): Promise<TResPayload> {
    return new Promise((resolve, reject) => {
      this.tunnel.sendRequest(context, reqPayload, (payload: any) => {
        resolve(payload as TResPayload)
      }, (message: string) => {
        reject(new Error(message))
      })
    })
  }

  /**
   * Sets a callback to handle navigator back.
   */
  setNavigatorBackCallback (callback: () => boolean) {
    this.navigatorBackCallback = callback
  }

  /**
   * Notifies that a user has been logged in.
   */
  async notifyLogin () {
    await this.sendRequest("LOGIN", {})
  }

  /**
   * Notifies that a user has been logged out.
   */
  async notifyLogout () {
    await this.sendRequest("LOGOUT", {})
  }

  /**
   * Requests to play a scene.
   * @param id ID of a scene.
   */
  async playScene (id: string) {
    await this.sendRequest("PLAY_SCENE", {
      id,
    })
  }

  /**
   * Requests to deletes browsing history.
   */
  async deleteHistory () {
    // Currently ingnoring error from Unity.
    this.sendRequest("DELETE_HISTORY", {}).catch(() => {
      console.warn("Deleting history not implemented in Unity. But ignoring")
    })
  }

  /**
   * For debugging. Sends a echo message.
   * @param message Message to send.
   */
  async echo (message: string) {
    if (!message) {
      throw new Error("Cannot send empty text.")
    }
    const payload = await this.sendRequest<
      { message: string },
      { message: string }
    >("ECHO", {
      message,
    })
    if (payload.message !== message) {
      throw new Error("Not received same message.")
    } else {
      return payload.message
    }
  }

  /**
   * Request to share a scene with native function.
   * @param id ID of a scene.
   * @param title Scene title.
   */
  async shareScene (id: string, title: string) {
    await this.sendRequest<
      { id: string, title: string, url: string },
      {}
    >("SHARE_SCENE", {
      id,
      title,
      url: `https://gallery.styly.cc/scene/${id}`,
    })
  }

  /**
   * Opens external web page.
   * @param url URL to open.
   */
  async openExternalPage (url: string) {
    await this.sendRequest<{ url: string }, {}>("OPEN_EXTERNAL_PAGE", { url, })
  }

  /**
   * Opens the camera to scan STYLY Marker.
   */
  async openCamera () {
    await this.sendRequest<{}, {}>("OPEN_CAMERA", {})
  }

  /**
   * Notifies Unity that web app is ready.
   */
  async notifyAppInitialized () {
    const resPayload = await this.sendRequest<
      {},
      { firstLaunch: any, debug: any }
    >("APP_INITIALIZED", {})
    if (typeof resPayload.firstLaunch !== "boolean") {
      throw new Error("Invalid response. firstLaunch is not boolean.")
    }
    let debug = false
    if (resPayload.debug !== undefined) {
      if (typeof resPayload.debug !== "boolean") {
        throw new Error("Invalid response. debug is not boolean.")
      } else {
        debug = resPayload.debug
      }
    }
    return {
      firstLaunch: resPayload.firstLaunch,
      debug,
    }
  }

  /**
   * Requests to ask a user to grant a specified permission.
   * Waits to get PERMISSION_GRANTED request from Unity.
   * @param permission
   */
  askPermission (permission: Permission): Promise<boolean> {
    if (this.permissionBeingAsked) {
      throw new Error("Already asking to grant a permission.")
    }
    return new Promise<boolean>((resolve, reject) => {
      this.permissionBeingAsked = permission
      this.askPermissionResolve = resolve
      this.askPermissionReject = reject
      this.sendRequest<{ type: Permission }, {}>(
        "ASK_PERMISSION",
        {
          type: permission,
        }
      )
    })
  }
}
