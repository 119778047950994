export function preventScroll () {
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = "fixed"
  document.body.style.zIndex = "-1"
  document.body.style.width = "100vw"
}

export function enableScroll () {
  const scrollY = document.body.style.top
  document.body.style.position = ""
  document.body.style.top = ""
  window.scrollTo(0, parseInt(scrollY || "0") * -1)
  document.body.style.width = ""
}
