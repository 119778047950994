import React from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import style from "./style.module.scss"
import { TabType, } from "../redux"

type Props = {
  tab: TabType,
  tabOrder: Array<string>,
  tabLabel: any,
  onChangeTab: (newValue: TabType) => void
}

export default function TabsContainer ({
  tab,
  tabOrder,
  tabLabel,
  onChangeTab,
}: Props) {
  /**
   * タブ切り替え時のコールバック
   */
  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: TabType) => {
    if (onChangeTab) onChangeTab(newValue)
  }

  return (
    <div className={style.tabsContainer}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
        variant="fullWidth"
      >
        {tabOrder.map(t => {
          return <Tab key={t} label={tabLabel[t]} value={t} />
        })}
      </Tabs>
    </div>
  )
}
