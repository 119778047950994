import React, { useCallback, useEffect, } from "react"
import style from "./style.module.scss"
import {
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
  selectState as appState,
} from "app/App/redux"
import { loadScene, selectState, } from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import {
  RouteComponentProps, Link,
} from "react-router-dom"
import TagComponent from "features/Tag"
import moment from "moment"
import InfoRow from "./InfoRow"
import Billboard from "./Billboard"
import Scene from "utils/api/models/Scene"
import Tag from "utils/api/models/Tag"
import history from "utils/history"
import { FEATURED_TAGS, AccessLevel, AccessLevelLabel, } from "app/constants"
import GoodIconButton, { IconSize as GoodIconButtonSize, } from "features/GoodIconButton"
import MylistIconButton, { IconSize as MylistIconButtonSize, } from "features/MylistIconButton"
import ShareIcon from "features/ShareIcon"
import Markdown from "features/Markdown"
import LicenseBox from "./LicenseBox"
import SceneGrid from "features/SceneGrid"
import { tracker, tunnelClient, } from "globalInstance"
import i18next from "i18next"
import LinkIcon from "@material-ui/icons/Link"
import LockIcon from "@material-ui/icons/Lock"
import PublicIcon from "@material-ui/icons/Public"
import DraftIcon from "@material-ui/icons/InsertDriveFile"

const mapDispatch = {
  loadScene,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
}

const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>

/**
 * Renders an access level badge.
 */
function renderPublishStatus (accessLevel: AccessLevel, published: boolean): React.ReactElement {
  let text = "DRAFT"
  let Icon = <DraftIcon className={style.icon} />
  if (published) {
    text = AccessLevelLabel[accessLevel]
    switch (accessLevel) {
      case AccessLevel.PUBLIC:
        Icon = <PublicIcon className={style.icon} />
        break
      case AccessLevel.UNLISTED:
        Icon = <LinkIcon className={style.icon} />
        break
      case AccessLevel.PRIVATE:
        Icon = <LockIcon className={style.icon} />
        break
      default:
        break
    }
  }
  return (
    <div className={style.publishStatus}>
      <div className={style.accessLevel}>
        {Icon}
        <div className={style.text}>
          {text}
        </div>
      </div>
      {!published && (
        <span className={style.draftMessage}>Only YOU can see this page.</span>
      )}
    </div>
  )
}

const renderTags = (tags: Array<Tag>) => {
  return (
    <div className={style.tagContainer}>
      {tags.map(t => {
        return (
          <Link key={t.id} to={`/search?q=${encodeURIComponent(`#${t.text}`)}`}>
            <TagComponent
              key={t.id}
              text={t.text}
              important={FEATURED_TAGS.includes(t.text)}
            />
          </Link>
        )
      })}
    </div>
  )
}

const ScenePage = ({
  loadScene,
  match,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
}: Props) => {
  const { id, } = match.params
  const { scene, licenses, recommendedScenes, isFollowed, } = useSelector(selectState)
  const { me, } = useSelector(appState)

  useEffect(() => {
    loadScene(id)
  }, [id, loadScene])

  const onShareClick = useCallback(async () => {
    if (scene) {
      tunnelClient.shareScene(scene.id, scene.title)
    }
  }, [scene])

  /**
   * いいねクリック時の処理
   */
  const onGoodClick = (sceneId: string, gooded: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    // いいね状況を更新
    if (gooded) {
      undoGood(sceneId)
    } else {
      doGood(sceneId)
    }
  }

  /**
   * マイリストアイコンクリック時の処理
   */
  const onMylistClick = (sceneId: string, mylisted: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    // マイリスト状況を更新
    if (mylisted) {
      removeFromMylist(sceneId)
    } else {
      addToMylist(sceneId)
    }
  }

  const onBillboardClick = useCallback(async () => {
    await tunnelClient.playScene(id)
    tracker.playScene(id)
  }, [id])

  const onClickInMarkDown = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      const aTag = e.target as HTMLAnchorElement
      await tunnelClient.openExternalPage(aTag.href)
    }, [])

  if (!scene) {
    return <div></div>
  }

  /**
   * FOLLOWクリック時の処理
   */
  const onFollowClick = (userId: number) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (isFollowed) {
      // フォローを解除する
      unfollow(userId)
    } else {
      // フォローする
      follow(userId)
    }
  }

  const renderSceneDetail = (
    scene: Scene
  ) => {
    const { user, } = scene
    return (
      <div>
        <div className={style.userSection}>
          <InfoRow
            user={user}
            date={scene.publishedAt ? moment(scene.publishedAt).format("YYYY/MM/DD") : "-"}
            profileImageUrl={user.profileImageUrl || null}
            isFollowed={isFollowed}
            onClick={onFollowClick}
          />
        </div>
        <div className={style.description}>
          {scene.description ? (
            <Markdown source={scene.description} onClickLink={onClickInMarkDown}/>
          ) : i18next.t("scenePage.noDescription")}
        </div>
      </div>
    )
  }

  return (
    <div className={style.frame}>
      <Billboard
        thumbnailUrl={scene.thumbnail.url}
        onClick={onBillboardClick}
      />
      <div className={style.documentWrapper}>
        {renderTags(scene.tags)}
        <div className={style.title}>{scene.title}</div>
        {renderPublishStatus(scene.accessLevel, scene.published)}
        <div className={style.statsRow}>
          <div className={style.goodIcon}>
            <GoodIconButton
              iconSize={GoodIconButtonSize.M}
              gooded={scene.gooded}
              onClick={() => onGoodClick(scene.id, scene.gooded)}
            />
          </div>
          <span className={style.goodCount}>
            {scene.goodCount}
          </span>
          <div className={style.goodIcon}>
            <MylistIconButton
              iconSize={MylistIconButtonSize.M}
              mylisted={scene.mylisted}
              onClick={() => onMylistClick(scene.id, scene.mylisted)}
            />
          </div>
          <div className={style.shareIcon}>
            <ShareIcon onClick={onShareClick} />
          </div>
        </div>
        {renderSceneDetail(scene)}
        {licenses.length > 0 && (
          <LicenseBox licenses={licenses.map(lines => lines.split(","))} />
        )}
      </div>
      {recommendedScenes.length > 0 && (
        <div className={style.recommended}>
          <div className={style.header}>{i18next.t("scenePage.recommendedScenes")}</div>
          <SceneGrid
            scenes={recommendedScenes}
            onGoodClick={onGoodClick}
            onMylistClick={onMylistClick}
          />
        </div>
      )}
    </div>
  )
}

export default connector(ScenePage)
