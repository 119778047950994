import React, { useCallback, useState, } from "react"
import classnames from "classnames"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import Button from "@material-ui/core/Button"
import { Link, } from "react-router-dom"
import AccountIcon from "features/AccountIcon"
import style from "./style.module.scss"
import PickupScene from "models/PickupScene"
import QrIcon from "icons/Qr"

type Props = {
  scene: PickupScene
  onMarkerClick?: (sceneId: string) => void
}

export default function SceneRow ({ scene, onMarkerClick, }: Props) {
  const [imageLoaded, setImageLoaded] = useState(false)
  const onMarkerClickInternal = useCallback(() => {
    onMarkerClick && onMarkerClick(scene.id)
  }, [onMarkerClick, scene.id])
  return (
    <div className={style.frame}>
      <div className={style.image}>
        <img
          className={classnames({
            [style.loaded]: imageLoaded,
          })}
          src={scene.image.url}
          alt="thumbnail"
          onLoad={() => setImageLoaded(true)}
        />
        <div className={style.overlay} />
      </div>
      <div className={style.meta}>
        <div className={style.title}>{scene.title}</div>
        <div className={style.user}>
          <div className={style.iconContainer}>
            <Link to={`/creator/${scene.user.username}`}>
              <AccountIcon
                username={scene.user.username}
                profileImageUrl={scene.user.profileImageUrl || null}
              />
            </Link>
          </div>
          <Link to={`/creator/${scene.user.username}`}>
            <div className={style.text}>{scene.user.username}</div>
          </Link>
        </div>
        <div className={style.description}>{scene.description}</div>
        <div className={style.buttonContainer}>
          <Button variant="contained" onClick={onMarkerClickInternal}>
            <QrIcon />
            <span>Marker</span>
          </Button>
          {(() => {
            const content = (
              <Button variant="contained">
                <InfoOutlinedIcon />
                <span>Details</span>
              </Button>
            )
            if (scene.articleUrl) {
              return (
                <a href={scene.articleUrl} target="_blank" rel="noopener noreferrer">
                  {content}
                </a>
              )
            }
            return <Link to={`/scene/${scene.id}`}>{content}</Link>
          })()}
        </div>
      </div>
    </div>
  )
}
