import isEmail from "validator/es/lib/isEmail"

export const FieldKey = {
  USERNAME: "username",
  EMAIL: "email",
  PASSWORD: "password",
}

export const validator = {
  [FieldKey.USERNAME]: (value: string) => !!value.match(/^[a-zA-Z0-9_-]+$/),
  [FieldKey.EMAIL]: (value: string) => isEmail(value),
  [FieldKey.PASSWORD]: (value: string) => !!value.match(/^[!-~]{4,25}$/),
}
