import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit"
import ApiClient from "utils/api"
import { contentfulClient, } from "globalInstance"
import PickupScene from "models/PickupScene"
import { RootState, } from "app/store"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import { logout, } from "app/App/redux"
import {
  API_BASE_PATH,
} from "app/env"

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/StaffPicksPage`

export const initialize = createAsyncThunk<PickupScene[], void, {}>(
  `${ACTION_TYPE_PREFIX}/initialize`,
  async (_) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    const staffPicks = await contentfulClient.getStaffPicks()
    const ids = staffPicks.map(s => {
      return s.sceneId
    })
    const apiScenes = await apiClient.getPublicScenesByIds(ids)
    const convertedScenes = staffPicks
      .map(pickupScene => {
        const scene = apiScenes.find(scene => {
          return scene.id === pickupScene.sceneId
        })
        if (!scene) {
          return null
        }
        return {
          id: scene.id,
          user: scene.user,
          title: scene.title,
          description: pickupScene.description,
          image: pickupScene.image || scene.thumbnail,
          articleUrl: pickupScene.articleUrl || "",
          gooded: scene.gooded,
          mylisted: scene.mylisted,
        } as PickupScene
      })
      .filter(s => s !== null) as PickupScene[]
    return convertedScenes
  }
)

const initialState = {
  initializing: false,
  scenes: [] as PickupScene[],
}

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/scenePage`,
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        return {
          ...state,
          initializing: true,
        }
      })
      .addCase(initialize.fulfilled, (state, action) => {
        return {
          ...state,
          initializing: false,
          scenes: action.payload,
        }
      })
      .addCase(logout.fulfilled, () => {
        // ログアウト後はstateを初期化する
        return initialState
      })
  },
})

export const selectState = (state: RootState) => state.staffPicksPage

export default slice.reducer
