import React, { useCallback, } from "react"
import AccountIcon, { Size, } from "../../../features/AccountIcon"
import { Link, } from "react-router-dom"
import User from "utils/api/models/User"
import FollowButton from "features/FollowButton"
import moment from "moment"
import style from "./style.module.scss"

type Props = {
  user: User
  isFollowed: boolean
  profileImageUrl: string | null
  date: string
  onClick: (userId: number) => void
}

const InfoRow = ({
  user,
  isFollowed,
  profileImageUrl,
  date,
  onClick,
}: Props) => {
  /**
   * フォローボタン押下時の処理
   */
  const onFollowClick = useCallback(() => {
    onClick(user.id)
  }, [user, onClick])

  return (
    <div className={style.frame}>
      <div className={style.contentContainer}>
        <div className={style.iconContainer}>
          <Link to={`/user/${user.id}`}>
            <AccountIcon
              username={user.username}
              size={Size.M}
              profileImageUrl={profileImageUrl}
            />
          </Link>
        </div>
        <div className={style.textContainer}>
          <div>
            <div className={style.usernmae}>
              <Link to={`/user/${user.id}`}>
                {user.username}
              </Link>
            </div>
          </div>
          <div className={style.date}>
            {moment(date).format("YYYY/MM/DD")}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <FollowButton
            isFollowed={isFollowed}
            onClick={onFollowClick}
          />
        </div>
      </div>
    </div>
  )
}

export default InfoRow
