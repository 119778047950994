import React, { useCallback, useEffect, useState, } from "react"
import style from "./style.module.scss"
import MobileStepper from "@material-ui/core/MobileStepper"
import SwipeableViews from "react-swipeable-views"
import { contentfulClient, tunnelClient, } from "globalInstance"
import Image from "models/Image"
import Button from "@material-ui/core/Button"
import history from "utils/history"
import { Permission, } from "utils/tunnel/client"

export default function OnboardingPage () {
  const [activeStep, setActiveStep] = useState(0)
  const [images, setImages] = useState<Image[]>([])

  const handleChangeIndex = useCallback((step: number) => {
    setActiveStep(step)
  }, [])

  const onNextClick = useCallback(() => {
    if (activeStep < images.length - 1) {
      setActiveStep(activeStep + 1)
    } else {
      (async () => {
        await tunnelClient.askPermission(Permission.CAMERA)
        await tunnelClient.askPermission(Permission.NOTIFICATION)
        history.push("/login?signup")
      })()
    }
  }, [activeStep, images])

  useEffect(() => {
    (async () => {
      const list = await contentfulClient.getOnboardingImages()
      setImages(list.images)
    })()
  }, [])

  return (
    <div className={style.frame}>
      <div className={style.wrapper}>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
        >
          {images.map((image, index) => (
            <div key={index} className={style.view}>
              <img src={image.url} className={style.img} alt='img' />
            </div>
          ))}
        </SwipeableViews>
        <MobileStepper
          steps={images.length}
          position='static'
          activeStep={activeStep}
          backButton={<div/>}
          nextButton={<div/>}
          className={style.stepper}
        />
        <div className={style.buttonContainer}>
          <Button onClick={onNextClick}>{images.length - 1 === activeStep ? "START" : "NEXT"}</Button>
        </div>
      </div>
    </div>
  )
}
