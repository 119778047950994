import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import Root from "./Root"
import "sanitize.css/sanitize.css"
import { contentfulClient, } from "globalInstance"
import { getLocale, } from "utils/locale"
contentfulClient.setLocale(getLocale())

ReactDOM.render(
  <Root />,
  document.getElementById("root")
)
