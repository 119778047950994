import React from "react"
import store from "app/store"
import { Provider, } from "react-redux"
import { Router, } from "react-router-dom"
import { ThemeProvider, } from "@material-ui/styles"
import history from "utils/history"
import App from "app/App"
import theme from "theme"
import { tracker, } from "./globalInstance"

// Opens communication tunnel to Unity backend.
history.listen(() => {
  // 画面が変わったときには上までスクロールする
  // Ref. https://www.w3schools.com/howto/howto_js_scroll_to_top.asp
  // この対応は history.back() には有効でないが今の所挙動に問題はなさそうなので気にしていない
  // 問題になったら変更が必要
  document.body.scrollTop = 0 // For iOS Safari
  document.documentElement.scrollTop = 0 // For Android Chrome
  tracker.pageview()
})

function Root () {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  )
}

export default Root
