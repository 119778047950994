export default {
  common: {
    cancel: "Cancel",
    change: "Change",
    close: "Close",
    delete: "Delete",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    details: "Details",
    login: "Login",
    logout: "Logout",
    signup: "Signup",
    email: "Email",
    password: "Password",
    username: "Username",
  },
  scenePage: {
    noDescription: "This scene has no description.",
    recommendedScenes: "Recommended scenes for you",
  },
  loginPage: {
    usernameErrorMessage: "Username must be alphabets, numbers, \"_\" or \"-\".",
    emailErrorMessage: "Invalid email",
    passwordErrorMessage: "Password must be alphabets, numbers or symbols. The length must be 4 or more AND 25 or less.",
    alreadyHaveAccount: "Already have account?",
    doNotHaveAccount: "Do not have account?",
    switchToLogin: "Switch to login",
    switchToSignup: "Switch to signup",
  },
  myPage: {
    noPublishedScene: "You don't have any scenes yet.",
    goStudioAndPublish: "Go to Studio and create scenes.",
    noMylistedScene: "You haven't added any scenes to Mylist yet.",
    mylistShowUp: "When you do it, it'll shows up here.",
    noFollowee: "You aren't following anyones yet.",
    followeeShowUp: "When you do it, they'll be listed.",
  },
}
