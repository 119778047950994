import React from "react"
import classnames from "classnames"
import style from "./style.module.scss"

function List () {
  return (
    <div className={style.pickupList}>
      <div className={style.header}>
        <div className={classnames(style.title, style.block)} />
      </div>
      <div className={style.list}>
        <div className={classnames(style.card, style.block)} />
        <div className={classnames(style.card, style.block)} />
        <div className={classnames(style.card, style.block)} />
        <div className={classnames(style.card, style.block)} />
        <div className={classnames(style.card, style.block)} />
      </div>
    </div>
  )
}

export default function LoadingPlaceholder () {
  return (
    <div className={style.frame}>
      <List />
      <List />
      <List />
      <List />
      <List />
    </div>
  )
}
