import hljs from "highlight.js/lib/core"

hljs.registerLanguage("cs", require("highlight.js/lib/languages/csp"))
hljs.registerLanguage("css", require("highlight.js/lib/languages/css"))
hljs.registerLanguage("glsl", require("highlight.js/lib/languages/glsl"))
hljs.registerLanguage(
  "javascript",
  require("highlight.js/lib/languages/javascript")
)
hljs.registerLanguage("json", require("highlight.js/lib/languages/json"))
hljs.registerLanguage("php", require("highlight.js/lib/languages/php"))
hljs.registerLanguage("sql", require("highlight.js/lib/languages/sql"))

export default hljs
