import React, { useState, } from "react"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import style from "./style.module.scss"
import classnames from "classnames"

type Props = {
  licenses: string[][]
}

export default function LicenseBox ({ licenses, }: Props) {
  const [collapsed, setCollapsed] = useState(true)

  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }
  const collapseEnabled = licenses.length > 1

  return (
    <div
      className={classnames({
        [style.frame]: true,
        [style.collapseEnabled]: collapseEnabled,
        [style.collapsed]: collapsed,
      })}
    >
      <div className={style.inner}>
        {licenses.map((lines, i) => {
          return <pre className={style.block} key={i}>{lines}</pre>
        })}
      </div>
      {collapseEnabled && (
        <div className={style.collapseRow} onClick={toggleCollapse}>
          <div
            className={classnames({
              [style.collapseButton]: true,
              [style.collapsed]: collapsed,
            })}
          >
            <div className={style.text}>
              {collapsed ? "SEE MORE" : "SEE LESS"}
            </div>
            <KeyboardArrowDownIcon />
          </div>
        </div>
      )}
    </div>
  )
}
