import React, { useEffect, useRef, } from "react"
import style from "./style.module.scss"
import {
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
  selectState as appState,
} from "app/App/redux"
import {
  initialize,
  fetchMoreScenes,
  fetchFollowing,
  fetchFollowerCount,
  selectState,
} from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import {
  RouteComponentProps,
} from "react-router-dom"
import history from "utils/history"
import SceneGrid from "features/SceneGrid"
import InfoRow from "./InfoRow"
import FollowButton from "features/FollowButton"
import SnsIcon, { SnsType, } from "features/SnsIcon/index"
import { tunnelClient, } from "globalInstance"

const mapDispatch = {
  initialize,
  fetchMoreScenes,
  doGood,
  undoGood,
  addToMylist,
  removeFromMylist,
  follow,
  unfollow,
  fetchFollowing,
  fetchFollowerCount,
}
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>

function UserPage (props: Props) {
  const { id, } = props.match.params
  const { me, } = useSelector(appState)
  useEffect(() => {
    const userId = Number(id)
    props.initialize(userId)
    if (me) {
      if (me.id === userId) {
        // 自身のユーザーページを表示した場合はマイページへ遷移する
        history.push("/mypage")
      } else {
        // ログインしている場合はフォロー状況を取得する
        props.fetchFollowing(userId)
      }
    }
  }, [props, id, me])

  const {
    initialized,
    initializing,
    fetchingScenes,
    scenes,
    user,
    isFollowed,
    total,
  } = useSelector(selectState)

  const gridRef = useRef<HTMLDivElement>(null)

  // incremental scene loading
  useEffect(() => {
    const trackScrolling = () => {
      if (initializing || fetchingScenes || scenes.length === total) {
        return
      }
      if (!gridRef?.current) {
        return
      }
      if (gridRef.current.getBoundingClientRect().bottom < window.innerHeight + 100) {
        props.fetchMoreScenes()
      }
    }

    document.addEventListener("scroll", trackScrolling)
    return () => document.removeEventListener("scroll", trackScrolling)
  }, [props, initializing, fetchingScenes, scenes.length, total])

  /**
   * いいねクリック時の処理
   */
  const onGoodClick = (sceneId: string, gooded: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (gooded) {
      props.undoGood(sceneId)
    } else {
      props.doGood(sceneId)
    }
  }

  /**
   * マイリストアイコンクリック時の処理
   */
  const onMylistClick = (sceneId: string, mylisted: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (mylisted) {
      props.removeFromMylist(sceneId)
    } else {
      props.addToMylist(sceneId)
    }
  }

  /**
   * FOLLOWクリック時の処理
   */
  const onFollowClick = async () => {
    if (!me) {
      history.push("/login?signup")
    }
    if (!user) return

    if (isFollowed) {
      // フォローを解除する
      await props.unfollow(user.id)
    } else {
      // フォローする
      await props.follow(user.id)
    }

    // フォロワー数を取得する
    props.fetchFollowerCount(user.username)
  }

  /**
   * SNSアイコンクリック時の処理
   */
  const onSnsIconClick = async (url: string) => {
    // urlを外部アプリで開く
    await tunnelClient.openExternalPage(url)
  }

  if (!initialized || initializing) {
    return <div></div>
  }

  return (
    <div className={style.frame}>
      <div>
        {user && (
          <InfoRow user={user} totalScenes={total} />
        )}
      </div>
      <FollowButton
        isFollowed={isFollowed}
        onClick={onFollowClick}
      />
      {user && (
        <div className={style.sns}>
          <SnsIcon snsType={SnsType.LINK} profile={user.siteUrl || ""} onClick={onSnsIconClick} />
          <SnsIcon snsType={SnsType.TWITTER} profile={user.twitterId || ""} onClick={onSnsIconClick} />
          <SnsIcon snsType={SnsType.INSTAGRAM} profile={user.instagramId || ""} onClick={onSnsIconClick} />
          <SnsIcon snsType={SnsType.FACEBOOK} profile={user.facebookId || ""} onClick={onSnsIconClick} />
          <SnsIcon snsType={SnsType.YOUTUBE} profile={user.youtubeChannelUrl || ""} onClick={onSnsIconClick} />
        </div>
      )}
      <div ref={gridRef}>
        <SceneGrid scenes={scenes} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
      </div>
    </div>
  )
}

export default connector(UserPage)
