/**
 * Supported locale
 * @enum {string}
 */
export enum Locale {
  JAPANESE = "ja",
  ENGLISH = "en-US",
}

/**
 * Gets locale from local storage. If not set, gets it from browser.
 * @param {Locale}
 */
export function getLocale (): Locale {
  return getBrowserLocale()
}

/**
 * Gets locale from browser and converts it to the format of this app.
 * @return {Locale}
 */
export function getBrowserLocale (): Locale {
  const first2Letters = window.navigator.language.slice(0, 2)
  switch (first2Letters) {
    case "ja":
      return Locale.JAPANESE
    default:
      return Locale.ENGLISH
  }
}
