export default {
  common: {
    cancel: "キャンセル",
    change: "変更",
    close: "閉じる",
    delete: "削除",
    termsOfUse: "利用規約",
    privacyPolicy: "プライバシーポリシー",
    details: "詳細",
    login: "ログイン",
    logout: "ログアウト",
    signup: "サインアップ",
    email: "Email",
    password: "パスワード",
    username: "ユーザーネーム",
  },
  scenePage: {
    noDescription: "シーンの説明文が書かれていません。",
    recommendedScenes: "おすすめのシーン",
  },
  loginPage: {
    usernameErrorMessage: "ユーザーネームは英数字、「_」、「-」のいずれかである必要がります",
    emailErrorMessage: "Email のフォーマットではありません",
    passwordErrorMessage: "パスワードは英数字、記号でかつ４文字以上、２５文字以下である必要があります",
    alreadyHaveAccount: "すでにアカウントをお持ちですか？",
    switchToLogin: "ログインに切り替える",
    doNotHaveAccount: "アカウントをお持ちでないですか?",
    switchToSignup: "サインアップに切り替える",
  },
  myPage: {
    noPublishedScene: "シーンがまだありません",
    goStudioAndPublish: "Studio でシーンをつくってみましょう",
    noMylistedScene: "Mylist に追加されたシーンがまだありません",
    mylistShowUp: "追加したらこちらに表示されます",
    noFollowee: "まだだれもフォローしていません",
    followeeShowUp: "フォローするとこちらに表示されます",
  },
}
