import React from "react"
import Me from "utils/api/models/Me"
import style from "./style.module.scss"
import AccountIcon, { Size, } from "features/AccountIcon"
import SnsIcon, { SnsType, } from "features/SnsIcon/index"

type Props = {
  me: Me
  totalScenes: number
  totalFollowers: number
  onSnsIconClick: (url: string) => void
}

export default function InfoRow ({
  me,
  totalScenes,
  totalFollowers,
  onSnsIconClick,
}: Props) {
  return (
    <div className={style.frame}>
      <div>
        <AccountIcon
          size={Size.L}
          username={me.username}
          profileImageUrl={me.profileImageUrl || null}
        />
      </div>
      <div className={style.username}>{me.username}</div>
      <div className={style.counts}>
        <span className={style.totalScenes}>{`${totalScenes} Scenes`}</span>
        <span>{`${totalFollowers} Followers`}</span>
      </div>
      <div className={style.sns}>
        <SnsIcon snsType={SnsType.LINK} profile={me.siteUrl} onClick={onSnsIconClick} />
        <SnsIcon snsType={SnsType.TWITTER} profile={me.twitterId} onClick={onSnsIconClick} />
        <SnsIcon snsType={SnsType.INSTAGRAM} profile={me.instagramId} onClick={onSnsIconClick} />
        <SnsIcon snsType={SnsType.FACEBOOK} profile={me.facebookId} onClick={onSnsIconClick} />
        <SnsIcon snsType={SnsType.YOUTUBE} profile={me.youtubeChannelUrl} onClick={onSnsIconClick} />
      </div>
    </div>
  )
}
