import React, { useState, useCallback, useEffect, } from "react"
import style from "./style.module.scss"
import { useLocation, Link, } from "react-router-dom"
import IconButton from "@material-ui/core/IconButton"
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import history from "utils/history"
import SearchBox from "reduxComponents/SearchBox"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"
import classnames from "classnames"
import logoUrl from "images/logo_horizontal.png"

/**
 * Floating bottom footer.
 */
export default React.memo(function Header () {
  const [showBack, setShowBack] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const goBack = useCallback(() => {
    history.goBack()
  }, [])
  const location = useLocation()
  const trigger = useScrollTrigger({ target: window, })

  useEffect(() => {
    const unlistenHistory = history.listen((location) => {
      if (location.pathname === "/" ||
          location.pathname.startsWith("/onboarding") ||
          location.pathname.startsWith("/login")) {
        setShowBack(false)
      } else {
        setShowBack(true)
      }
    })
    const scrollEventListener = () => {
      const scrolled = document.scrollingElement?.scrollTop !== 0 ?? false
      setScrolled(scrolled)
    }
    document.addEventListener("scroll", scrollEventListener)
    return () => {
      document.removeEventListener("scroll", scrollEventListener)
      unlistenHistory()
    }
  }, [setScrolled])

  useEffect(() => {
    if (location.pathname.startsWith("/login") ||
        location.pathname.startsWith("/onboarding")) {
      setShowSearch(false)
    } else {
      setShowSearch(true)
    }
  }, [location])

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <div
        className={classnames({
          [style.frame]: true,
          [style.filled]: scrolled,
        })}
      >
        <div className={style.inner}>
          <div className={style.backButton}>
            {showBack && false && (
              <IconButton onClick={goBack}>
                <ArrowLeftIcon />
              </IconButton>
            )}
          </div>
          <Link to='/'>
            <img src={logoUrl} alt="logo" />
          </Link>
          {showSearch && (<SearchBox />)}
        </div>
      </div>
    </Slide>
  )
})
