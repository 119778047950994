import React, { SyntheticEvent, } from "react"
import classnames from "classnames"
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd"
import IconButton from "@material-ui/core/IconButton"
import style from "./style.module.scss"

export enum IconSize {
  S = "small",
  M = "default",
  L = "large"
}

type Props = {
  iconSize: IconSize
  mylisted: boolean
  onClick: (e: SyntheticEvent) => void
}

export default React.memo(function MylistIconButton ({
  iconSize, mylisted, onClick,
}: Props) {
  return (
    <IconButton onClick={onClick}>
      <PlaylistAddIcon
        fontSize={iconSize}
        className={classnames({
          [style.mylist]: true,
          [style.mylisted]: mylisted,
        })}
      />
    </IconButton>
  )
})
