import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit"
import ApiClient from "utils/api"
import Scene from "utils/api/models/Scene"
import SceneListItem from "utils/api/models/SceneListItem"
import { RootState, } from "app/store"
import { FEATURED_TAGS, REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import {
  API_BASE_PATH,
} from "app/env"
import { doGood, undoGood, addToMylist, removeFromMylist, follow, unfollow, logout, } from "app/App/redux"

const ACTION_TYPE_PREFIX = `${REDUX_ACTION_TYPE_PREFIX}/scenePage`

export const loadScene = createAsyncThunk<Scene, string, {}>(
  `${ACTION_TYPE_PREFIX}/loadScene`,
  async (id: string, thunkAPI) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    thunkAPI.dispatch(loadLiceses(id))
    thunkAPI.dispatch(loadRecommendedScenes(id))
    const scene = await apiClient.getScene(id)
    scene.tags = scene.tags.sort((a, b) => {
      return a.text > b.text ? 1 : -1
    }).sort((a, b) => {
      return Number(FEATURED_TAGS.includes(b.text)) - Number(FEATURED_TAGS.includes(a.text))
    })
    const state = thunkAPI.getState() as RootState
    const { me, } = state.app
    if (me) {
      // ログインしている場合はフォロー状況を取得する
      thunkAPI.dispatch(fetchFollowing(scene.user.id))
    }
    return scene
  }
)

export const loadLiceses = createAsyncThunk<string[] | null, string, {}>(
  `${ACTION_TYPE_PREFIX}/loadLiceses`,
  async (id: string) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    return await apiClient.getSceneLicenses(id)
  }
)

export const loadRecommendedScenes = createAsyncThunk<SceneListItem[], string, {}>(
  `${ACTION_TYPE_PREFIX}/loadRecommendedScenes`,
  async (id: string) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    return await apiClient.getRecemmendedScenesBySceneId(id)
  }
)

/**
 * フォロー状況を取得するアクション
 */
const fetchFollowing = createAsyncThunk<boolean, number>(
  `${ACTION_TYPE_PREFIX}/fetchFollowing`,
  async (id: number) => {
    const apiClient = new ApiClient(API_BASE_PATH)
    return await apiClient.checkFollowing(id)
  }
)

const initialState = {
  scene: null as (null| Scene),
  isFollowed: false,
  licenses: [] as string[],
  recommendedScenes: [] as SceneListItem[],
}

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/scenePage`,
  initialState: initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadScene.pending, (state) => {
        return {
          ...state,
          scene: null,
        }
      })
      .addCase(loadScene.fulfilled, (state, action) => {
        return {
          ...state,
          scene: action.payload,
        }
      })
      .addCase(loadLiceses.fulfilled, (state, action) => {
        const licenses = action.payload
        return {
          ...state,
          licenses: licenses || [],
        }
      })
      .addCase(loadRecommendedScenes.fulfilled, (state, action) => {
        return {
          ...state,
          recommendedScenes: action.payload,
        }
      })
      .addCase(doGood.fulfilled, (state, action) => {
        // いいね状況を更新する
        const sceneId = action.payload
        if (state.scene) changeSceneGooded(true, state.scene, sceneId)
        changeRecommendedScenesGooded(true, state.recommendedScenes, sceneId)
        return state
      })
      .addCase(undoGood.fulfilled, (state, action) => {
        // いいね状況を更新する
        const sceneId = action.payload
        if (state.scene) changeSceneGooded(false, state.scene, sceneId)
        changeRecommendedScenesGooded(false, state.recommendedScenes, sceneId)
        return state
      })
      .addCase(addToMylist.fulfilled, (state, action) => {
        // マイリスト状況を更新する
        const sceneId = action.payload.id
        if (state.scene) changeSceneMylisted(true, state.scene, sceneId)
        changeRecommendedScenesMylisted(true, state.recommendedScenes, sceneId)
      })
      .addCase(removeFromMylist.fulfilled, (state, action) => {
        // マイリスト状況を更新する
        const sceneId = action.payload
        if (state.scene) changeSceneMylisted(false, state.scene, sceneId)
        changeRecommendedScenesMylisted(false, state.recommendedScenes, sceneId)
      })
      .addCase(fetchFollowing.fulfilled, (state, action) => {
        // フォロー状況を更新する
        return {
          ...state,
          isFollowed: action.payload,
        }
      })
      .addCase(follow.fulfilled, (state, action) => {
        const userId = action.payload
        // フォロー状況を更新する
        if (state.scene && state.scene.user.id === userId) {
          state.isFollowed = true
        }
        return state
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        const userId = action.payload
        // フォロー状況を更新する
        if (state.scene && state.scene.user.id === userId) {
          state.isFollowed = false
        }
        return state
      })
      .addCase(logout.fulfilled, () => {
        // ログアウト後はstateを初期化する
        return initialState
      })
  },
})

/**
 * シーン詳細情報のマイリスト状況を変更する
 */
const changeSceneMylisted = (mylisted: boolean, scene: Scene, sceneId: string) => {
  if (scene.id !== sceneId) return

  scene.mylisted = mylisted
}

/**
 * リコメンドシーンのリストに対して
 * sceneIdが指すシーンのマイリスト状況を変更する
 */
const changeRecommendedScenesMylisted = (mylisted: boolean, recommendedScenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < recommendedScenes.length; i++) {
    const scene = recommendedScenes[i]
    if (scene.id !== sceneId) continue

    scene.mylisted = mylisted
    break
  }
}

/**
 * シーン詳細情報のいいね状況を変更する
 */
const changeSceneGooded = (gooded: boolean, scene: Scene, sceneId: string) => {
  if (scene.id !== sceneId) return

  scene.gooded = gooded
  if (gooded) {
    scene.goodCount += 1
  } else {
    scene.goodCount -= 1
  }
}

/**
 * リコメンドシーンのリストに対して
 * sceneIdが指すシーンのいいね状況を変更する
 */
const changeRecommendedScenesGooded = (gooded: boolean, recommendedScenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < recommendedScenes.length; i++) {
    const scene = recommendedScenes[i]
    if (scene.id !== sceneId) continue

    scene.gooded = gooded
    break
  }
}

export const selectState = (state: RootState) => state.scenePage

export default slice.reducer
