import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit"
import ApiClient from "utils/api"
import SceneListItem from "utils/api/models/SceneListItem"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import { RootState, } from "app/store"
import { doGood, undoGood, addToMylist, removeFromMylist, logout, } from "app/App/redux"
import { API_BASE_PATH, } from "app/env"
import { contentfulClient, } from "globalInstance"

/**
 * Number of scenes in a chunk to load.
 */
export const NUM_SCENES_OF_CHUNK = 48

const client = new ApiClient(API_BASE_PATH)
const sliceName = `${REDUX_ACTION_TYPE_PREFIX}/sceneSearch`

export const fetchScenes = createAsyncThunk(
  `${sliceName}/fetchScenes`,
  async (query: string) => {
    const res = await client.searchScenes(query, NUM_SCENES_OF_CHUNK)

    let pickupScenes: SceneListItem[] = []
    if (query) {
      const pickupSceneList = await contentfulClient.getPickupSceneListBySearchQuery(query)
      if (pickupSceneList) {
        const pickupContentfulScenes = pickupSceneList.scenes
        const pickupSceneIds = pickupContentfulScenes.map((scene) => {
          return scene.sceneId
        })
        pickupScenes = await client.getPublicScenesByIds(pickupSceneIds)
      }
    }
    return {
      pickupScenes,
      scenes: res.scenes,
      total: res.total,
    }
  }
)

export const fetchMoreScenes = createAsyncThunk(
  `${sliceName}/fetchMoreScenes`,
  async (query: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const offset = state.sceneSearch.scenes.length
    const res = await client.searchScenes(query, NUM_SCENES_OF_CHUNK, offset)
    return res
  }
)

const initialState = {
  pickupScenes: [] as SceneListItem[],
  scenes: [] as SceneListItem[],
  loadingScenes: false,
  initialLoadDone: false,
  total: 0,
}

export const sceneSearchSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: { },
  extraReducers: builder => {
    builder
    // fetchScenes
      .addCase(
        fetchScenes.pending,
        (state) => {
          state.loadingScenes = true
          state.initialLoadDone = false
        })
      .addCase(
        fetchScenes.rejected,
        (state) => {
          state.loadingScenes = false
        })
      .addCase(
        fetchScenes.fulfilled,
        (state, action) => {
          state.loadingScenes = false
          state.initialLoadDone = true
          state.pickupScenes = action.payload.pickupScenes
          state.scenes = action.payload.scenes
          state.total = action.payload.total
        })

    // fetchMoreScenes
      .addCase(
        fetchMoreScenes.pending,
        (state) => {
          state.loadingScenes = true
        })
      .addCase(
        fetchMoreScenes.rejected,
        (state) => {
          state.loadingScenes = false
        })
      .addCase(
        fetchMoreScenes.fulfilled,
        (state, action) => {
          state.loadingScenes = false
          state.scenes = state.scenes.concat(action.payload.scenes)
        })

      .addCase(
        doGood.fulfilled,
        (state, action) => {
          // いいね状況を更新する
          const sceneId = action.payload
          changeScenesGooded(true, state.scenes, sceneId)
          return state
        }
      )
      .addCase(
        undoGood.fulfilled,
        (state, action) => {
          // いいね状況を更新する
          const sceneId = action.payload
          changeScenesGooded(false, state.scenes, sceneId)
          return state
        }
      )

      .addCase(
        addToMylist.fulfilled,
        (state, action) => {
          // マイリスト状況を更新する
          const sceneId = action.payload.id
          changeScenesMylisted(true, state.scenes, sceneId)
        }
      )
      .addCase(
        removeFromMylist.fulfilled,
        (state, action) => {
          // マイリスト状況を更新する
          const sceneId = action.payload
          changeScenesMylisted(false, state.scenes, sceneId)
        }
      )
      .addCase(
        logout.fulfilled, () => {
          // ログアウト後はstateを初期化する
          return initialState
        }
      )
  },
})

/**
 * 検索結果のリストに対して
 * sceneIdが指すシーンのマイリスト状況を変更する
 */
const changeScenesMylisted = (mylisted: boolean, scenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < scenes.length; i++) {
    const scene = scenes[i]
    if (scene.id !== sceneId) continue

    scene.mylisted = mylisted
    break
  }
}

/**
 * 検索結果のリストに対して
 * sceneIdが指すシーンのいいね状況を変更する
 */
const changeScenesGooded = (gooded: boolean, scenes: SceneListItem[], sceneId: string) => {
  for (let i = 0; i < scenes.length; i++) {
    const scene = scenes[i]
    if (scene.id !== sceneId) continue

    scene.gooded = gooded
    break
  }
}

export default sceneSearchSlice.reducer
