import React, { KeyboardEvent, ChangeEvent, useRef, useState, } from "react"
import { ConnectedProps, connect, } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"
import classnames from "classnames"
import style from "./style.module.scss"
import { changeText, search, } from "./redux"
import { RootState, } from "app/store"
import { createPortal, } from "react-dom"

const mapState = (state: RootState) => ({ ...state.searchBox, })
const mapDispatch = { changeText, search, }
const connector = connect(mapState, mapDispatch)
type Props = ConnectedProps<typeof connector>

function SearchBox ({ changeText, search, text, }: Props) {
  const floatingTextIput = useRef<HTMLInputElement>(null)
  const [floatingShown, setFloatingShown] = useState(false)

  /**
   * Fires when search input text changes.
   */
  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeText(e.target.value)
  }
  /**
   * Fires when floating opener is clicked.
   */
  const onFloatingOpenClick = () => {
    setFloatingShown(true)
    floatingTextIput.current?.focus()
  }
  /**
   * Fires when floating close is clicked.
   */
  const onFloatingCloseClick = () => {
    setFloatingShown(false)
    floatingTextIput.current?.blur()
  }
  const onFloatingBlur = () => {
    setFloatingShown(false)
  }
  /**
   * Fires when search icon is clicked.
   */
  const onSearchButtonClick = () => {
    search()
    onFloatingCloseClick()
  }
  /**
   * Fires when ENTER key is pressed in text input element.
   */
  const onSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      search()
      onFloatingCloseClick()
    }
  }

  return (
    <div className={style.frame}>
      {/* icon to show the floating box */}
      <IconButton
        className={style.floatingOpener}
        onClick={onFloatingOpenClick}
      >
        <SearchIcon />
      </IconButton>
      {/* floating box for mobile */}
      {createPortal(
        <div
          className={classnames({
            [style.floatingBox]: true,
            [style.shown]: floatingShown,
          })}
        >
          <IconButton className={style.search} onClick={onSearchButtonClick}>
            <SearchIcon />
          </IconButton>
          <input
            className={style.textInput}
            inputMode="search"
            onChange={onTextChange}
            onKeyPress={onSearchInputKeyPress}
            placeholder="Search scenes ..."
            ref={floatingTextIput}
            onBlur={onFloatingBlur}
            value={text}
          />
          <IconButton className={style.clear} onClick={onFloatingCloseClick}>
            <ClearIcon />
          </IconButton>
        </div>,
        window.document.body
      )}
      {/* fixed box for tablet and desktop */}
      <div className={style.fixedBox}>
        <input
          className={style.textInput}
          inputMode="search"
          onChange={onTextChange}
          onKeyPress={onSearchInputKeyPress}
          placeholder="Search scenes ..."
          value={text}
        />
        <div className={style.iconContainer} onClick={onSearchButtonClick}>
          <SearchIcon />
        </div>
      </div>
    </div>
  )
}

export default connector(SearchBox)
