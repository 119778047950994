import React from "react"
import classnames from "classnames"
import LinkIcon from "@material-ui/icons/Link"
import { ReactComponent as TwitterIcon, } from "@fortawesome/fontawesome-free/svgs/brands/twitter.svg"
import { ReactComponent as InstagramIcon, } from "@fortawesome/fontawesome-free/svgs/brands/instagram.svg"
import { ReactComponent as FacebookIcon, } from "@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg"
import { ReactComponent as YoutubeIcon, } from "@fortawesome/fontawesome-free/svgs/brands/youtube.svg"
import ButtonBase from "@material-ui/core/ButtonBase"
import style from "./style.module.scss"

export enum SnsType {
  LINK = "link",
  TWITTER = "twitter",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  YOUTUBE = "youtube"
}

type Props = {
  snsType: SnsType,
  profile: string | null,
  onClick: (url: string) => void
}

export default function SnsIcon ({
  snsType,
  profile,
  onClick,
}: Props) {
  let url = null as string | null
  let icon = null
  switch (snsType) {
    case SnsType.LINK: {
      url = profile
      icon = <LinkIcon />
      break
    }
    case SnsType.TWITTER: {
      url = `https://twitter.com/${profile}`
      icon = <TwitterIcon />
      break
    }
    case SnsType.INSTAGRAM: {
      url = `https://www.instagram.com/${profile}`
      icon = <InstagramIcon />
      break
    }
    case SnsType.FACEBOOK: {
      url = `https://www.facebook.com/profile.php?id=${profile}`
      icon = <FacebookIcon />
      break
    }
    case SnsType.YOUTUBE: {
      url = profile
      icon = <YoutubeIcon />
      break
    }
  }
  const handleIconClick = () => {
    if (profile && url) onClick(url)
  }
  return (
    <ButtonBase
      className={style.frame}
      onClick={handleIconClick}
      centerRipple={true}
      disabled={!profile}
    >
      <div className={classnames({
        [style.socialIcon]: true,
        [style.hasProfile]: !!profile,
      })}>
        {icon}
      </div>
    </ButtonBase>
  )
}
