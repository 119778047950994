import React, { useEffect, useCallback, } from "react"
import style from "./style.module.scss"
import { doGood, undoGood, addToMylist, removeFromMylist, selectState as appState, deleteBrowingHistory, } from "app/App/redux"
import { initialize, selectState, } from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import PickupSceneListComponent from "./PickupSceneList"
import AdList from "./AdList"
import Tag from "features/Tag"
import Billboard from "./Billboard"
import { Link, } from "react-router-dom"
import LoadingPlaceholder from "./LoadingPlaceholder"
import history from "utils/history"
import { tunnelClient, } from "globalInstance"

const mapDispatch = { initialize, doGood, undoGood, addToMylist, removeFromMylist, deleteBrowingHistory, }
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector>

/**
 * Top Page. Holds Picked up scenes like Staff picks.
 */
function TopPage ({ initialize, doGood, undoGood, addToMylist, removeFromMylist, deleteBrowingHistory, }: Props) {
  /**
   * Opens ad in brower. Sends URL open request to Unity.
   */
  const onAdClick = useCallback(async (url: string) => {
    await tunnelClient.openExternalPage(url)
  }, [])

  const { me, } = useSelector(appState)
  const state = useSelector(selectState)
  const {
    initializing,
    initialized,
    pickupSceneLists,
    pickupSceneListMap,
    ads,
    featuredTags,
    staffPicksScenes,
  } = state

  useEffect(() => {
    deleteBrowingHistory()
  }, [deleteBrowingHistory])

  useEffect(() => {
    if (!initialized) {
      initialize()
    }
  }, [initialize, initialized])

  const onBillboardClick = useCallback((sceneId: string) => {
    history.push(`/scene/${sceneId}`)
  }, [])

  /**
   * いいねクリック時の処理
   */
  const onGoodClick = (sceneId: string, gooded: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (gooded) {
      undoGood(sceneId)
    } else {
      doGood(sceneId)
    }
  }

  /**
   * マイリストアイコンクリック時の処理
   */
  const onMylistClick = (sceneId: string, mylisted: boolean) => {
    if (!me) {
      history.push("/login?signup")
    }

    if (mylisted) {
      removeFromMylist(sceneId)
    } else {
      addToMylist(sceneId)
    }
  }

  if (initializing || !initialized) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <div className={style.frame}>
      <Billboard
        scenes={staffPicksScenes}
        onClick={onBillboardClick}
        onGoodClick={onGoodClick}
        onMylistClick={onMylistClick}
      />
      <AdList ads={ads} onAdClick={onAdClick}/>
      <div className={style.tagContainer}>
        <div>
          {featuredTags.filter(t => t.important).map(t => {
            return (
              <Link key={t.id} to={`/search?q=${encodeURIComponent("#" + t.text)}`}>
                <Tag text={t.text} important={t.important}/>
              </Link>
            )
          })}
        </div>
        <div>
          {featuredTags.filter(t => !t.important).map(t => {
            return (
              <Link key={t.id} to={`/search?q=${encodeURIComponent("#" + t.text)}`}>
                <Tag text={t.text} important={t.important}/>
              </Link>
            )
          })}
        </div>
      </div>
      {pickupSceneLists.map(list => {
        const scenes = pickupSceneListMap[list.id]
        if (scenes) {
          return (
            <PickupSceneListComponent
              key={list.id}
              list={list}
              scenes={scenes}
              viewAllPath={`/search?q=${window.encodeURIComponent(list.searchQuery)}`}
              onGoodClick={onGoodClick}
              onMylistClick={onMylistClick}
            />
          )
        } else {
          return <></>
        }
      })}
    </div>
  )
}

export default connector(TopPage)
