/* tslint:disable */
/* eslint-disable */
/**
 * STYLY API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateTagsRequest
 */
export interface CreateTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTagsRequest
     */
    texts: Array<string>;
}
/**
 * 
 * @export
 * @interface EmailChangeWithCodeRequest
 */
export interface EmailChangeWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailChangeWithCodeRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface FollowCheckResponse
 */
export interface FollowCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FollowCheckResponse
     */
    following: boolean;
}
/**
 * Full user properties. This object is given to only login user himself/herself. 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    api_key: string;
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    plan: number;
    /**
     * 
     * @type {UserFeatures}
     * @memberof LoginUser
     */
    features: UserFeatures;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    profile_image_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    facebook_id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    instagram_id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    twitter_id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    site_url: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    youtube_channel_url: string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    current: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    _new: string;
}
/**
 * 
 * @export
 * @interface PasswordResetWithCodeRequest
 */
export interface PasswordResetWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetWithCodeRequest
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetWithCodeRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface RefreshApiKeyResponse
 */
export interface RefreshApiKeyResponse {
    /**
     * 
     * @type {string}
     * @memberof RefreshApiKeyResponse
     */
    api_key: string;
}
/**
 * 
 * @export
 * @interface Scene
 */
export interface Scene {
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Scene
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_tiny_url: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_small_url: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_middle_url: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    thumbnail_large_url: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Scene
     */
    tags: Array<Tag>;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    access_level: number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    published_at: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Scene
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof Scene
     */
    good_count: number;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    gooded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Scene
     */
    mylisted: boolean;
}
/**
 * 
 * @export
 * @interface SceneCreationRequest
 */
export interface SceneCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof SceneCreationRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SceneCreationRequest
     */
    template_scene_id: string;
}
/**
 * 
 * @export
 * @interface SceneCreationResponse
 */
export interface SceneCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof SceneCreationResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SceneIdSearchRequest
 */
export interface SceneIdSearchRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SceneIdSearchRequest
     */
    scene_ids: Array<string>;
}
/**
 * 
 * @export
 * @interface SceneList
 */
export interface SceneList {
    /**
     * 
     * @type {Array<SceneListItem>}
     * @memberof SceneList
     */
    scenes: Array<SceneListItem>;
}
/**
 * 
 * @export
 * @interface SceneListItem
 */
export interface SceneListItem {
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof SceneListItem
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_tiny_url: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_small_url: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_middle_url: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    thumbnail_large_url: string;
    /**
     * 
     * @type {number}
     * @memberof SceneListItem
     */
    access_level: number;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof SceneListItem
     */
    published_at: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    gooded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SceneListItem
     */
    mylisted: boolean;
}
/**
 * 
 * @export
 * @interface ScenePublishInfo
 */
export interface ScenePublishInfo {
    /**
     * 
     * @type {string}
     * @memberof ScenePublishInfo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ScenePublishInfo
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScenePublishInfo
     */
    tag_ids: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScenePublishInfo
     */
    access_level: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScenePublishInfo
     */
    access_user_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface SceneSearchResult
 */
export interface SceneSearchResult {
    /**
     * 
     * @type {Array<SceneListItem>}
     * @memberof SceneSearchResult
     */
    scenes: Array<SceneListItem>;
    /**
     * 
     * @type {number}
     * @memberof SceneSearchResult
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SendEmailChangeEmailRequest
 */
export interface SendEmailChangeEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendEmailChangeEmailRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface SendPasswordResetEmailRequest
 */
export interface SendPasswordResetEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPasswordResetEmailRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    text: string;
}
/**
 * 
 * @export
 * @interface TagList
 */
export interface TagList {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagList
     */
    tags: Array<Tag>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profile_image_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    facebook_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    instagram_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    twitter_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    site_url: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    youtube_channel_url: string;
}
/**
 * User UI features.
 * @export
 * @interface UserFeatures
 */
export interface UserFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    can_change_plan: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    can_manage_locations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFeatures
     */
    mobile_skip_top: boolean;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserList
     */
    users: Array<User>;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    facebook_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    instagram_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    twitter_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    site_url?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    youtube_channel_url?: string;
}
/**
 * 
 * @export
 * @interface UserSearchResult
 */
export interface UserSearchResult {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserSearchResult
     */
    users: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof UserSearchResult
     */
    total: number;
}

/**
 * SceneApi - axios parameter creator
 * @export
 */
export const SceneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMyScene: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling copyMyScene.');
            }
            const localVarPath = `/scene/scene/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSceneFromTemplate: async (sceneCreationRequest: SceneCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneCreationRequest' is not null or undefined
            if (sceneCreationRequest === null || sceneCreationRequest === undefined) {
                throw new RequiredError('sceneCreationRequest','Required parameter sceneCreationRequest was null or undefined when calling createNewSceneFromTemplate.');
            }
            const localVarPath = `/scene/scene/new_from_template`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sceneCreationRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sceneCreationRequest !== undefined ? sceneCreationRequest : {}) : (sceneCreationRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteScene.');
            }
            const localVarPath = `/scene/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets scenes of a login user
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyScenes: async (query?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene/my_scenes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicScenesByIds: async (sceneIdSearchRequest: SceneIdSearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sceneIdSearchRequest' is not null or undefined
            if (sceneIdSearchRequest === null || sceneIdSearchRequest === undefined) {
                throw new RequiredError('sceneIdSearchRequest','Required parameter sceneIdSearchRequest was null or undefined when calling getPublicScenesByIds.');
            }
            const localVarPath = `/scene/id_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sceneIdSearchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sceneIdSearchRequest !== undefined ? sceneIdSearchRequest : {}) : (sceneIdSearchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPuslishInfo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPuslishInfo.');
            }
            const localVarPath = `/scene/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getScene.');
            }
            const localVarPath = `/scene/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByUserId: async (id: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getScenesByUserId.');
            }
            const localVarPath = `/scene/user/{id}/scenes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (id: string, scenePublishInfo: ScenePublishInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling publish.');
            }
            // verify required parameter 'scenePublishInfo' is not null or undefined
            if (scenePublishInfo === null || scenePublishInfo === undefined) {
                throw new RequiredError('scenePublishInfo','Required parameter scenePublishInfo was null or undefined when calling publish.');
            }
            const localVarPath = `/scene/scene/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof scenePublishInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(scenePublishInfo !== undefined ? scenePublishInfo : {}) : (scenePublishInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (query?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/scene/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneApi - functional programming interface
 * @export
 */
export const SceneApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyMyScene(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).copyMyScene(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneCreationResponse>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).createNewSceneFromTemplate(sceneCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScene(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).deleteScene(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets scenes of a login user
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyScenes(query?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).getMyScenes(query, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneList>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).getPublicScenesByIds(sceneIdSearchRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPuslishInfo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenePublishInfo>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).getPuslishInfo(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScene(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scene>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).getScene(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenesByUserId(id: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).getScenesByUserId(id, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(id: string, scenePublishInfo: ScenePublishInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).publish(id, scenePublishInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(query?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneSearchResult>> {
            const localVarAxiosArgs = await SceneApiAxiosParamCreator(configuration).search(query, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SceneApi - factory interface
 * @export
 */
export const SceneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Make a copy of a scene
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyMyScene(id: string, options?: any): AxiosPromise<SceneCreationResponse> {
            return SceneApiFp(configuration).copyMyScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a scene from template.
         * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any): AxiosPromise<SceneCreationResponse> {
            return SceneApiFp(configuration).createNewSceneFromTemplate(sceneCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScene(id: string, options?: any): AxiosPromise<SuccessResponse> {
            return SceneApiFp(configuration).deleteScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets scenes of a login user
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyScenes(query?: string, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return SceneApiFp(configuration).getMyScenes(query, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets scenes by IDs
         * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, options?: any): AxiosPromise<SceneList> {
            return SceneApiFp(configuration).getPublicScenesByIds(sceneIdSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info (meta) of a scene for publish.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPuslishInfo(id: string, options?: any): AxiosPromise<ScenePublishInfo> {
            return SceneApiFp(configuration).getPuslishInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a scene.
         * @param {string} id Scene ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScene(id: string, options?: any): AxiosPromise<Scene> {
            return SceneApiFp(configuration).getScene(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets by user ID
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenesByUserId(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return SceneApiFp(configuration).getScenesByUserId(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish a scene
         * @param {string} id Scene ID
         * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(id: string, scenePublishInfo: ScenePublishInfo, options?: any): AxiosPromise<SuccessResponse> {
            return SceneApiFp(configuration).publish(id, scenePublishInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches scenes
         * @param {string} [query] Query to search scenes.
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(query?: string, limit?: number, offset?: number, options?: any): AxiosPromise<SceneSearchResult> {
            return SceneApiFp(configuration).search(query, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneApi - object-oriented interface
 * @export
 * @class SceneApi
 * @extends {BaseAPI}
 */
export class SceneApi extends BaseAPI {
    /**
     * 
     * @summary Make a copy of a scene
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public copyMyScene(id: string, options?: any) {
        return SceneApiFp(this.configuration).copyMyScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a scene from template.
     * @param {SceneCreationRequest} sceneCreationRequest Payload to create a scene with a template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public createNewSceneFromTemplate(sceneCreationRequest: SceneCreationRequest, options?: any) {
        return SceneApiFp(this.configuration).createNewSceneFromTemplate(sceneCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a scene.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public deleteScene(id: string, options?: any) {
        return SceneApiFp(this.configuration).deleteScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets scenes of a login user
     * @param {string} [query] Query to search scenes.
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getMyScenes(query?: string, limit?: number, offset?: number, options?: any) {
        return SceneApiFp(this.configuration).getMyScenes(query, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets scenes by IDs
     * @param {SceneIdSearchRequest} sceneIdSearchRequest Payload having scene IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getPublicScenesByIds(sceneIdSearchRequest: SceneIdSearchRequest, options?: any) {
        return SceneApiFp(this.configuration).getPublicScenesByIds(sceneIdSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info (meta) of a scene for publish.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getPuslishInfo(id: string, options?: any) {
        return SceneApiFp(this.configuration).getPuslishInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a scene.
     * @param {string} id Scene ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScene(id: string, options?: any) {
        return SceneApiFp(this.configuration).getScene(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets by user ID
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getScenesByUserId(id: number, limit?: number, offset?: number, options?: any) {
        return SceneApiFp(this.configuration).getScenesByUserId(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish a scene
     * @param {string} id Scene ID
     * @param {ScenePublishInfo} scenePublishInfo Payload to update scene with.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public publish(id: string, scenePublishInfo: ScenePublishInfo, options?: any) {
        return SceneApiFp(this.configuration).publish(id, scenePublishInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches scenes
     * @param {string} [query] Query to search scenes.
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public search(query?: string, limit?: number, offset?: number, options?: any) {
        return SceneApiFp(this.configuration).search(query, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags: async (createTagsRequest: CreateTagsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTagsRequest' is not null or undefined
            if (createTagsRequest === null || createTagsRequest === undefined) {
                throw new RequiredError('createTagsRequest','Required parameter createTagsRequest was null or undefined when calling createTags.');
            }
            const localVarPath = `/tag/tag`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createTagsRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createTagsRequest !== undefined ? createTagsRequest : {}) : (createTagsRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsByIds: async (ids: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getTagsByIds.');
            }
            const localVarPath = `/tag/id_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTags(createTagsRequest: CreateTagsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await TagApiAxiosParamCreator(configuration).createTags(createTagsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsByIds(ids: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagList>> {
            const localVarAxiosArgs = await TagApiAxiosParamCreator(configuration).getTagsByIds(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates tags with texts
         * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTags(createTagsRequest: CreateTagsRequest, options?: any): AxiosPromise<TagList> {
            return TagApiFp(configuration).createTags(createTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets tags by IDs
         * @param {Array<string>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsByIds(ids: Array<string>, options?: any): AxiosPromise<TagList> {
            return TagApiFp(configuration).getTagsByIds(ids, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary Creates tags with texts
     * @param {CreateTagsRequest} createTagsRequest Payload including texts to creat tags.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public createTags(createTagsRequest: CreateTagsRequest, options?: any) {
        return TagApiFp(this.configuration).createTags(createTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets tags by IDs
     * @param {Array<string>} ids Comma separated IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getTagsByIds(ids: Array<string>, options?: any) {
        return TagApiFp(this.configuration).getTagsByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailWithCode: async (emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailChangeWithCodeRequest' is not null or undefined
            if (emailChangeWithCodeRequest === null || emailChangeWithCodeRequest === undefined) {
                throw new RequiredError('emailChangeWithCodeRequest','Required parameter emailChangeWithCodeRequest was null or undefined when calling changeEmailWithCode.');
            }
            const localVarPath = `/user/change_email_with_code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof emailChangeWithCodeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailChangeWithCodeRequest !== undefined ? emailChangeWithCodeRequest : {}) : (emailChangeWithCodeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFollowing: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling checkFollowing.');
            }
            const localVarPath = `/user/user/{id}/following`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        follow: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling follow.');
            }
            const localVarPath = `/user/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowees: async (id: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFollowees.');
            }
            const localVarPath = `/user/user/{id}/followees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowers: async (id: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFollowers.');
            }
            const localVarPath = `/user/user/{id}/followers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
            }
            const localVarPath = `/user/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByIds: async (ids: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling getUsersByIds.');
            }
            const localVarPath = `/user/id_search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refreshs API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshApiKey: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/refresh_api_key`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordResetRequest: PasswordResetRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            if (passwordResetRequest === null || passwordResetRequest === undefined) {
                throw new RequiredError('passwordResetRequest','Required parameter passwordResetRequest was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/user/reset_password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof passwordResetRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(passwordResetRequest !== undefined ? passwordResetRequest : {}) : (passwordResetRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordWithCode: async (passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetWithCodeRequest' is not null or undefined
            if (passwordResetWithCodeRequest === null || passwordResetWithCodeRequest === undefined) {
                throw new RequiredError('passwordResetWithCodeRequest','Required parameter passwordResetWithCodeRequest was null or undefined when calling resetPasswordWithCode.');
            }
            const localVarPath = `/user/reset_password_with_code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof passwordResetWithCodeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(passwordResetWithCodeRequest !== undefined ? passwordResetWithCodeRequest : {}) : (passwordResetWithCodeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailChangeEmail: async (sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendEmailChangeEmailRequest' is not null or undefined
            if (sendEmailChangeEmailRequest === null || sendEmailChangeEmailRequest === undefined) {
                throw new RequiredError('sendEmailChangeEmailRequest','Required parameter sendEmailChangeEmailRequest was null or undefined when calling sendEmailChangeEmail.');
            }
            const localVarPath = `/user/send_email_change_email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sendEmailChangeEmailRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sendEmailChangeEmailRequest !== undefined ? sendEmailChangeEmailRequest : {}) : (sendEmailChangeEmailRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetEmail: async (sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPasswordResetEmailRequest' is not null or undefined
            if (sendPasswordResetEmailRequest === null || sendPasswordResetEmailRequest === undefined) {
                throw new RequiredError('sendPasswordResetEmailRequest','Required parameter sendPasswordResetEmailRequest was null or undefined when calling sendPasswordResetEmail.');
            }
            const localVarPath = `/user/send_password_reset_email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sendPasswordResetEmailRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sendPasswordResetEmailRequest !== undefined ? sendPasswordResetEmailRequest : {}) : (sendPasswordResetEmailRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollow: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unfollow.');
            }
            const localVarPath = `/user/user/{id}/follow`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfile} userProfile Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (userProfile: UserProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfile' is not null or undefined
            if (userProfile === null || userProfile === undefined) {
                throw new RequiredError('userProfile','Required parameter userProfile was null or undefined when calling updateProfile.');
            }
            const localVarPath = `/user/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userProfile !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userProfile !== undefined ? userProfile : {}) : (userProfile || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).changeEmailWithCode(emailChangeWithCodeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFollowing(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FollowCheckResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).checkFollowing(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async follow(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).follow(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowees(id: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getFollowees(id, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFollowers(id: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getFollowers(id, limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUser>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getMe(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByIds(ids: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUsersByIds(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Refreshs API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshApiKey(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshApiKeyResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).refreshApiKey(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordResetRequest: PasswordResetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).resetPassword(passwordResetRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).resetPasswordWithCode(passwordResetWithCodeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).sendEmailChangeEmail(sendEmailChangeEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordResetEmail(sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).sendPasswordResetEmail(sendPasswordResetEmailRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unfollow(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).unfollow(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfile} userProfile Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(userProfile: UserProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateProfile(userProfile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Changes account email with code.
         * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).changeEmailWithCode(emailChangeWithCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check following or not.
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFollowing(id: number, options?: any): AxiosPromise<FollowCheckResponse> {
            return UserApiFp(configuration).checkFollowing(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Follows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        follow(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).follow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followees of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowees(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return UserApiFp(configuration).getFollowees(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets followers of a login user
         * @param {number} id User ID
         * @param {number} [limit] Limit of scenes to get.
         * @param {number} [offset] Offset of search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFollowers(id: number, limit?: number, offset?: number, options?: any): AxiosPromise<UserSearchResult> {
            return UserApiFp(configuration).getFollowers(id, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a login user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<LoginUser> {
            return UserApiFp(configuration).getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets users by IDs
         * @param {Array<number>} ids Comma separated IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByIds(ids: Array<number>, options?: any): AxiosPromise<UserList> {
            return UserApiFp(configuration).getUsersByIds(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refreshs API key of a login user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshApiKey(options?: any): AxiosPromise<RefreshApiKeyResponse> {
            return UserApiFp(configuration).refreshApiKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets login user\'s password
         * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).resetPassword(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets password with validation code
         * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).resetPasswordWithCode(passwordResetWithCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends email change verification email.
         * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).sendEmailChangeEmail(sendEmailChangeEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends password reset email.
         * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetEmail(sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).sendPasswordResetEmail(sendPasswordResetEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unfollows a user
         * @param {number} id User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unfollow(id: number, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).unfollow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates profile of a user
         * @param {UserProfile} userProfile Profile object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(userProfile: UserProfile, options?: any): AxiosPromise<SuccessResponse> {
            return UserApiFp(configuration).updateProfile(userProfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Changes account email with code.
     * @param {EmailChangeWithCodeRequest} emailChangeWithCodeRequest Payload with code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeEmailWithCode(emailChangeWithCodeRequest: EmailChangeWithCodeRequest, options?: any) {
        return UserApiFp(this.configuration).changeEmailWithCode(emailChangeWithCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check following or not.
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkFollowing(id: number, options?: any) {
        return UserApiFp(this.configuration).checkFollowing(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Follows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public follow(id: number, options?: any) {
        return UserApiFp(this.configuration).follow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followees of a login user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowees(id: number, limit?: number, offset?: number, options?: any) {
        return UserApiFp(this.configuration).getFollowees(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets followers of a login user
     * @param {number} id User ID
     * @param {number} [limit] Limit of scenes to get.
     * @param {number} [offset] Offset of search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getFollowers(id: number, limit?: number, offset?: number, options?: any) {
        return UserApiFp(this.configuration).getFollowers(id, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a login user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(options?: any) {
        return UserApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: number, options?: any) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets users by IDs
     * @param {Array<number>} ids Comma separated IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersByIds(ids: Array<number>, options?: any) {
        return UserApiFp(this.configuration).getUsersByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refreshs API key of a login user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public refreshApiKey(options?: any) {
        return UserApiFp(this.configuration).refreshApiKey(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets login user\'s password
     * @param {PasswordResetRequest} passwordResetRequest Payload with current and new passwords.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(passwordResetRequest: PasswordResetRequest, options?: any) {
        return UserApiFp(this.configuration).resetPassword(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets password with validation code
     * @param {PasswordResetWithCodeRequest} passwordResetWithCodeRequest Payload with validation code and new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPasswordWithCode(passwordResetWithCodeRequest: PasswordResetWithCodeRequest, options?: any) {
        return UserApiFp(this.configuration).resetPasswordWithCode(passwordResetWithCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends email change verification email.
     * @param {SendEmailChangeEmailRequest} sendEmailChangeEmailRequest Payload with email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendEmailChangeEmail(sendEmailChangeEmailRequest: SendEmailChangeEmailRequest, options?: any) {
        return UserApiFp(this.configuration).sendEmailChangeEmail(sendEmailChangeEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends password reset email.
     * @param {SendPasswordResetEmailRequest} sendPasswordResetEmailRequest Payload with email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendPasswordResetEmail(sendPasswordResetEmailRequest: SendPasswordResetEmailRequest, options?: any) {
        return UserApiFp(this.configuration).sendPasswordResetEmail(sendPasswordResetEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unfollows a user
     * @param {number} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unfollow(id: number, options?: any) {
        return UserApiFp(this.configuration).unfollow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates profile of a user
     * @param {UserProfile} userProfile Profile object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateProfile(userProfile: UserProfile, options?: any) {
        return UserApiFp(this.configuration).updateProfile(userProfile, options).then((request) => request(this.axios, this.basePath));
    }
}


