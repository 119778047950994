/**
 * Redux action type prefix for this project.
 * Please use this prefix for all actions.
 * e.g. STYLY_MOBILE/sampleAction
 */
export const REDUX_ACTION_TYPE_PREFIX = "STYLY_MOBILE"

/**
 * Featrued tag
 */
export const FEATURED_TAGS = [
  "AR",
  "Art",
  "Architecture",
  "Entertainment",
  "Fashion",
  "Music",
  "VR"
]

export const ScreenUpperWidth = {
  PHONE: 640,
}

/**
 * Types of access.
 */
export enum AccessLevel {
  UNKNOWN = 0,
  PUBLIC = 1,
  UNLISTED = 2,
  PRIVATE = 3,
}

/**
 * Label for access restrictions.
 */
export const AccessLevelLabel = {
  [AccessLevel.UNKNOWN]: "UNKNOWN",
  [AccessLevel.PUBLIC]: "PUBLIC",
  [AccessLevel.UNLISTED]: "UNLISTED",
  [AccessLevel.PRIVATE]: "PRIVATE",
}
