import i18next from "i18next"
import { initReactI18next, } from "react-i18next"
import { Locale, } from "../locale"
import enTranslation from "./en"
import jaTranslation from "./ja"

export default async function init (locale: Locale): Promise<void> {
  await i18next.use(initReactI18next).init({
    lng: locale,
    fallbackLng: "en",
    resources: {
      en: {
        translation: enTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
    },
  })
}
