import React, { useState, SyntheticEvent, } from "react"
import style from "./style.module.scss"
import classnames from "classnames"
import LazyLoad from "react-lazyload"
import SceneListItem from "utils/api/models/SceneListItem"
import { Link, } from "react-router-dom"
import logoUrl from "images/logo_horizontal.png"
import AccountIcon from "features/AccountIcon"
import GoodIconButton, { IconSize as GoodIconButtonSize, } from "features/GoodIconButton"
import MylistIconButton, { IconSize as MylistIconButtonSize, } from "features/MylistIconButton"

interface SceneGridProps {
  scenes: SceneListItem[]
  onGoodClick: (id: string, gooded: boolean) => void
  onMylistClick: (id: string, mylisted: boolean) => void
}

function SceneGrid ({ scenes, onGoodClick, onMylistClick, }: SceneGridProps) {
  return (
    <div className={style.cardContainer}>
      {scenes.map(scene => {
        return (
          <Link key={scene.id} to={`/scene/${scene.id}`}>
            <SceneCard scene={scene} onGoodClick={onGoodClick} onMylistClick={onMylistClick} />
          </Link>
        )
      })}
    </div>
  )
}

interface SceneCardProps {
  scene: SceneListItem
  onGoodClick?: (id: string, gooded: boolean) => void
  onMylistClick?: (id: string, mylisted: boolean) => void
}

const SceneCard = ({ scene, onMylistClick = undefined, onGoodClick = undefined, }: SceneCardProps) => {
  const { title, user, mylisted, gooded, thumbnail, } = scene

  // Whether to show thumbnail or not.
  // This is used for lazy loading control.
  const [thumbnailVisible, setThumbnailVisible] = useState(false)

  // Fires when thumbnail image is laoded.
  const handleThubmnailLoad = () => {
    setThumbnailVisible(true)
  }
  // Fires when failed to laod thumbnail image.
  const handleThubmnailLoadError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.style.display = "none"
  }
  // いいねポタンが押されたときのコールバック
  const handleGoodClick = (e: SyntheticEvent, scene: SceneListItem) => {
    e.stopPropagation()
    e.preventDefault()
    if (onGoodClick) onGoodClick(scene.id, scene.gooded || false)
  }
  // マイリストに追加ポタンが押されたときのコールバック
  const handleMylistClick = (e: SyntheticEvent, scene: SceneListItem) => {
    e.stopPropagation()
    e.preventDefault()
    if (onMylistClick) onMylistClick(scene.id, scene.mylisted || false)
  }
  /**
   * 作者のページに飛ばすために必要 !!
   * イベントバブリングを阻止する
   */
  const handleUsernameClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className={style.frame}>
      <div
        className={style.background}
        style={{
          backgroundImage: `url(${logoUrl})`,
        }}
      />
      <LazyLoad height="100%">
        <img
          className={classnames(
            style.thumbnail,
            { [style.visible]: thumbnailVisible, }
          )}
          alt="Scene thumbnail"
          src={thumbnail.url}
          onError={handleThubmnailLoadError}
          onLoad={handleThubmnailLoad}
        />
      </LazyLoad>
      <div className={style.shadowCover}>
        <div className={style.contentContainer}>
          <div className={style.title}>{title}</div>
          { user &&
            <>
              <div className={style.userIcon}>
                <Link to={`/user/${user.id}`}>
                  <AccountIcon
                    username={user.username}
                    profileImageUrl={user.profileImageUrl || ""}
                  />
                </Link>
              </div>
              <div className={style.username} onClick={handleUsernameClick}>
                <Link to={`/user/${user.id}`}>
                  <span>{user.username}</span>
                </Link>
              </div>
              <div className={style.statsRowContainer}>
                <GoodIconButton
                  iconSize={GoodIconButtonSize.L}
                  gooded={gooded || false}
                  onClick={(e) => handleGoodClick(e, scene)}
                />
                <MylistIconButton
                  iconSize={MylistIconButtonSize.L}
                  mylisted={mylisted || false}
                  onClick={(e) => handleMylistClick(e, scene)}
                />
              </div>
            </>
          }
          <div className={style.statsRowContainer}>
            <GoodIconButton
              iconSize={GoodIconButtonSize.L}
              gooded={gooded || false}
              onClick={(e) => handleGoodClick(e, scene)}
            />
            <MylistIconButton
              iconSize={MylistIconButtonSize.L}
              mylisted={mylisted || false}
              onClick={(e) => handleMylistClick(e, scene)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SceneGrid
