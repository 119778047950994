import React, { useEffect, useCallback, } from "react"
import style from "./style.module.scss"
import { initialize, selectState, } from "./redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import SceneRow from "./SceneRow"
import LoadingPlaceholder from "./LoadingPlaceholder"
import { slice as appSlice, } from "app/App/redux"

const mapDispatch = {
  initialize,
  showMarker: appSlice.actions.showMarker,
}
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector>

function StaffPicksPage (props: Props) {
  const { scenes, initializing, } = useSelector(selectState)
  const { showMarker, } = props

  const openMarker = useCallback((sceneId: string) => {
    const scene = scenes.find(s => s.id === sceneId)
    if (scene) {
      showMarker({ id: scene.id, title: scene.title, thumbnailUrl: scene.image.url || "", })
    }
  }, [scenes, showMarker])

  useEffect(() => {
    props.initialize()
  }, [props])

  if (initializing) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <>
      <div className={style.frame}>
        {scenes.map(scene => {
          return (
            <SceneRow key={scene.id} scene={scene} onMarkerClick={openMarker}/>
          )
        })}
      </div>
    </>
  )
}

export default connector(StaffPicksPage)
