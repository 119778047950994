import React, { SyntheticEvent, } from "react"
import ShareIcon from "@material-ui/icons/Share"
import IconButton from "@material-ui/core/IconButton"
import style from "./style.module.scss"

type Props = {
  onClick: (e: SyntheticEvent) => void
}

export default function StatsRow ({ onClick, }: Props) {
  return (
    <IconButton
      onClick={onClick}
      className={style.share}
    >
      <ShareIcon />
    </IconButton>
  )
}
