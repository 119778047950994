import { createSlice, PayloadAction, } from "@reduxjs/toolkit"
import { RootState, } from "app/store"
import { REDUX_ACTION_TYPE_PREFIX, } from "app/constants"
import history from "utils/history"

export const slice = createSlice({
  name: `${REDUX_ACTION_TYPE_PREFIX}/searchBox`,
  initialState: {
    text: "",
  },
  reducers: {
    changeText: (state, action: PayloadAction<string>) => {
      state.text = action.payload
    },
    search: (state) => {
      history.push(`/search?q=${window.encodeURIComponent(state.text)}`)
    },
  },
})

export const selectState = (state: RootState) => state.scenePage
export const { changeText, search, } = slice.actions

export default slice.reducer
